import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';




import { ServicesService } from '../../shared/services/services.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit {

  public promotions: any = [];
  public loading = false;
  colorPrimario:any;
  colorLetra:any;
  configuracion:any;
  promotionOwl: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<i class="fas fa-angle-double-left" style="color: red;"></i>',
      '<i class="fas fa-angle-double-right text-ligth"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      }
    },
    nav: true
  }

  urlBase = environment.firebaseUrl;

  constructor(
    private webService: ServicesService,
    private toaster: ToastrService,
    private modalCtrl: NgbModal,
    private router: Router,

  ) { }

  async ngOnInit(){
    await this.getPromotion();
    await this.getConfiguracion();
  }


  async getPromotion(){
    this.loading = true;
    await this.webService.getPromotion().then((respromo: any) =>{

      for (let p of respromo.data) {

        if (p.nombrePromocion) {
          p.titulo = p.nombrePromocion.slice(0, 33);
        } else {
          p.titulo = '';
        }

        if (p.descripcion) {
          if (p.descripcion.length > 95) {
            p.preliminar = p.descripcion.slice(0, 95);
            p.vermas = true;
          } else {
            p.preliminar = p.descripcion;
            p.vermas = false;
          }
        } else {
          p.preliminar = '';
          p.vermas = false;
        }

      }

      this.promotions = respromo.data;
      // //console.log(this.promotions);
      
    });
    this.loading = false;
  }

  async getConfiguracion() {
    this.webService.getConfiguracion().then(async (data: any) => {
      if (!data.error) {
        this.configuracion = data[0];
        this.colorPrimario = this.configuracion.colorPrincipal;
        this.colorLetra = this.configuracion.colorLetra;
        
      } else {
        this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  colorUno(){
    // //console.log('P',this.colorPrimario );
    return this.colorPrimario 
    
  }
  colorLeter(){
    return this.colorLetra;
  }

  async seeMoreDescription(promocion) {
    promocion.view = !promocion.view;
  }

  async selectedPromotion(promotion){
    // //console.log('id',promotion );
    
    this.router.navigateByUrl('inicio/' + promotion.id_producto)
  }

}
