import { Injectable, EventEmitter } from "@angular/core";
import { AppRepository } from "../repositories/app.repository";
import { ServicesService } from "../../app/shared/services/services.service";

// const CLIENT_ID = "80298309010-06c845o7f0ng23rnrt602rrec71slfjd.apps.googleusercontent.com";
// const API_KEY = "AIzaSyBJFw0HNEY_dWULM8-kKXafUsKbkrQqKnk";
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];
var SCOPES = 'https://www.googleapis.com/auth/drive';

@Injectable()
export class GapiSession {

    googleAuth: gapi.auth2.GoogleAuth;
    public CLIENT_ID = "";
    public API_KEY = "";

    constructor(
        private appRepository: AppRepository,
        private webService: ServicesService
    ) {
    }

    async initClient() {
        // Obtener las credendiales del api Drive
        await this.webService.getCredentialsDrive().then((res) => {
            this.CLIENT_ID = res.client_id;
            this.API_KEY = res.api_key;
        });
        return new Promise(async (resolve, reject) => {
            gapi.load('client:auth2', () => {
                return gapi.client.init({
                    apiKey: this.API_KEY,
                    clientId: this.CLIENT_ID,
                    discoveryDocs: DISCOVERY_DOCS,
                    scope: SCOPES,
                }).then(() => {
                    this.googleAuth = gapi.auth2.getAuthInstance();
                    resolve();
                });
            });
        });
    }

    get isSignedIn(): boolean {
        return this.googleAuth.isSignedIn.get();
    }

    signIn() {
        return this.googleAuth.signIn({
            prompt: 'consent'
        }).then((googleUser: gapi.auth2.GoogleUser) => {
            this.appRepository.User.add(googleUser.getBasicProfile());
        });
    }

    signOut(): void {
        this.googleAuth.signOut();
    }
}