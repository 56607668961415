
export const environment = {
  // production: false
  production: true,
  firebaseConfig : {
    apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
    authDomain: "softpymes-webshop.firebaseapp.com",
    projectId: "softpymes-webshop",
    storageBucket: "softpymes-webshop.appspot.com",
    messagingSenderId: "609230356232",
    appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
    measurementId: "G-26PY63ZLHW"
  },
  firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/rosaldelsol%2F',
  idShop:49,
  empresa:'rosaldelsol'
};
// export const environment = {
//   // production: false
//   production: true,
//   firebaseConfig : {
//     apiKey: "AIzaSyBn0wvRb6oRVraDkFLNAoGa-fIrO2to9jw",
//     authDomain: "softpymes-webshop.firebaseapp.com",
//     projectId: "softpymes-webshop",
//     storageBucket: "softpymes-webshop.appspot.com",
//     messagingSenderId: "609230356232",
//     appId: "1:609230356232:web:ed1654a5f1f9e77ac5f6bd",
//     measurementId: "G-26PY63ZLHW"
//   },
//   firebaseUrl: 'https://firebasestorage.googleapis.com/v0/b/softpymes-webshop.appspot.com/o/demohotel%2F',
//   idShop:44,
//   empresa:'demohotel'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
