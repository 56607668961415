<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop: true }" [template]="">
</ngx-loading>


<section>

    <div class="container-fluid">
        <div class="row d-flex d-md-block flex-nowrap wrapper">

            <div class="col-lg-2 float-left col-1 pl-0 pr-0 collapse width show" id="sidebar">
                <div class="list-group border-0 text-center text-md-left">

                    <a class="list-group-item d-inline-block collapsed tipo-cursor opc-menu" (click)="goHome()">
                        <i class="fas fa-house-user"></i>
                        <span class="d-none d-md-inline">Inicio</span>
                    </a>

                    <hr>

                    <a (click)="selectMenu('datosGenerales')"
                        class="list-group-item d-inline-block collapsed tipo-cursor opc-menu">
                        <i class="fas fa-cogs"></i>
                        <span class="d-none d-md-inline">Datos Generales</span>
                    </a>

                    <a (click)="selectMenu('reservas')"
                        class="list-group-item d-inline-block collapsed tipo-cursor opc-menu">
                        <i class="fas fa-clipboard-list"></i>
                        <span class="d-none d-md-inline">Reservas</span>
                    </a>

                    <hr>

                    <a class="list-group-item d-inline-block collapsed tipo-cursor opc-menu" (click)="goHome()">
                        <i class="fas fa-arrow-circle-left"></i>
                        <span class="d-none d-md-inline">Regresar</span>
                    </a>

                    <a class="list-group-item d-inline-block collapsed tipo-cursor opc-menu" (click)="signOff()">
                        <i class="fas fa-lock text-danger"></i>
                        <span class="d-none d-md-inline">Cerrar Sesión</span>
                    </a>

                </div>
            </div>

            <main class="col-lg-10 float-left pl-5 w-100">

                <br>

                <div class="container" *ngIf="menu.datosGenerales">
                    <div class="form-group text-size-14">
                        <label for="nombre">Nombres</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="user.nombres" disabled>
                    </div>

                    <div class="form-group text-size-14">
                        <label for="apelidos">Apellidos</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="user.apellidos" disabled>
                    </div>

                    <div class="form-group text-size-14">
                        <label for="correo">Correo</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="user.email" disabled>
                    </div>
              
                    <div class="form-group text-size-14">
                        <label for="direccion">Dirección</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="user.direccion" disabled>
                    </div>
                   
                    <div class="form-group text-size-14">
                        <label for="telefono">Teléfono</label>
                        <input style="font-size: 13px;" class="form-control form-control-sm text-normal" type="text"
                            name="" id="" [(ngModel)]="user.telefonos" disabled>
                    </div>
                </div>

                <div class="container" *ngIf="menu.reservas">

                    <div class="row form-group">

                        <div class="col-lg-12 table-responsive">
                            <table class="table table-hover text-size-13" *ngIf="reservations.length > 0">
                                <thead>
                                    <tr class="text-center">
                                        <th scope="col">Ref.</th>
                                        <th scope="col"># Habitación</th>
                                        <th scope="col">Fecha Check-In</th>
                                        <th scope="col">Fecha Check-On</th>
                                        <th scope="col">Estado</th>
                                        <th scope="col"></th>
            
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let r of reservations" class="text-size-13 text-normal text-center">
                                        <td>{{r.id_res}}</td>
                                        <td>{{r.nro}}</td>
                                        <td>{{r.fecha_checkin}}</td>
                                        <td>{{r.fecha_checkout}}</td>
                                        <td class="{{r.color}}">{{r.status}}</td>
                                        <td *ngIf="r.estado == 0 && (r.pin != 0)"><i class="fas fa-check-circle text-success text-size-16"></i></td>
                                        <td *ngIf="r.estado == 1 && (r.pin != 0)"><i class="fas fa-clock text-warning text-size-16"></i></td>
                                        <td *ngIf="r.estado == 2 && (r.pin == 0)"><i class="fas fa-calendar-times text-danger text-size-16" (click)="ModaldeleteFile(deleteFileModal, r, 'cancelarReserva')"></i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>

            </main>

        </div>
    </div>

</section>


<!-- Alerta para eliminar un archivo -->
<ng-template #deleteFileModal let-modal>

    <div class="row pt-3 tipo-letra text-size-12">
        <div class="col-12 text-center text-size-13">
            <span>Seguro que desea cancelar la reserva</span>
        </div>
    </div>

    <div class="row modal-body tipo-letra text-center">

        <div class="col-12 text-center">

            <button type="button" class="btn btn-outline-danger text-center" (click)="modal.dismiss('Cross click')">
                <i class="fas fa-minus-circle"></i>
            </button>

            <button type="button" class="btn btn-outline-success text-center ml-3" (click)="modal.close()">
                <i class="fas fa-check-circle"></i>
            </button>

        </div>

    </div>

</ng-template>