import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


// import { AppContext } from '../../../../infrastructure/app.context';
// import { FileInfo } from 'src/models/fileInfo';

// Drive
import { AppContext } from '../../../../infrastructure/app.context';
import { FileInfo } from '../../../../models/fileInfo';


import { ServicesService } from '../../../shared/services/services.service';
import { MigrationService } from 'src/app/shared/services/migration.service';
import { environment } from 'src/environments/environment';
import firebase from "firebase/app";
import "firebase/storage";
import { HttpClient } from '@angular/common/http';
class ImageSnippet {
  constructor(public src: string, public file: File) { }
}


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  public apiLoxafree = "https://www.pulpoplace.com:8448/";
  public menu = {
    datosGenerales: true,
    sobreNosotros: false,
    descuentos: false,
    imagenesBanner: false,
    imagenesHabitaciones: false,
    credenciales: false,
    promociones: false
  }

  public configuracion: any = {};
  public information: any = {};
  public promotions: any = {};
  public imagesBanner = [];
  public typesRooms = [];
  public rooms = [];
  public viewRooms = {
    types: true,
    rooms: false,
  }
  public location = {
    latitud: '',
    longitud: '',
    update: false
  }
  public updCredentials = {
    user: '',
    password: '',
    newPassword: '',
    currentPassword: ''
  }
  public newPromotion = {
    title: '',
    description: '',
    image: '',
    id_producto: '',
    viewRoom: false
  }
  public loading = false;
  selectedFile: ImageSnippet;
  public closeResult: string;
  public room_for_person = false;

  //URLS PARA FIREBASE QUE SE REEMPLAZAN EN LA BD

  urlLogo_sobre_nosotros_grupos = 'urlLogo_sobre_nosotros_grupos%2F';
  urlLogo_sobre_nosotros_gruposFB = 'urlLogo_sobre_nosotros_grupos';

  urlHabitaciones = 'urlLogo_habitaciones%2F';
  urlHabitacionesFB = 'urlLogo_habitaciones';

  urlProductos= 'productos%2F';
  urlProductosFB= 'productos';

  urlBanner = 'banner%2F';
  urlBannerFB = 'banner';

  urlPromociones = 'promociones%2F';
  urlPromocionesFB = 'promociones';

  empresa = environment.empresa;
  urlBase = environment.firebaseUrl;
  sistema = environment.empresa;
  public id_empresa = environment.idShop;
  typeRoomSelect :any;

  constructor(
    private appContext: AppContext,
    private webService: ServicesService,
    private toaster: ToastrService,
    private modalCtrl: NgbModal,
    private fireService: MigrationService,
    private http: HttpClient,

  ) {
  }

  async ngOnInit() {

    // if (!this.appContext.Session.Gapi.isSignedIn) {
    //   await this.signInGoogle();
    // }

    this.loading = true;

    await this.getConfiguration();
    await this.getInformation();
    await this.getRoomForPerson();
    await this.getImagesBanner();
    await this.getRoomsTypes();
    await this.verifyLoginClient();
    await this.getPromotion();

    this.loading = false;

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // async signInGoogle() {
  //   this.appContext.Session.Gapi.signIn().then(() => {
  //     //console.log("Logeo google");
  //   })
  // }

  async verifyLoginClient() {
    await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then(async (login: any) => {
      if (login.rta == true) {
        this.updCredentials.user = login.data.usuario;
        this.updCredentials.password = login.data.password;
      }
    });
  }

  async selectMenu(type) {
    this.menu = {
      datosGenerales: false,
      sobreNosotros: false,
      descuentos: false,
      imagenesBanner: false,
      imagenesHabitaciones: false,
      credenciales: false,
      promociones: false
    }

    if (type == "datosGenerales") {
      this.menu.datosGenerales = true;
    }
    if (type == "sobreNosotros") {
      this.menu.sobreNosotros = true;
    }
    if (type == "descuentos") {
      this.menu.descuentos = true;
    }
    if (type == "imagenesBanner") {
      this.menu.imagenesBanner = true;
    }
    if (type == "imagenesHabitaciones") {
      this.menu.imagenesHabitaciones = true;
    }
    if (type == "credenciales") {
      this.menu.credenciales = true;
    }
    if (type == "promociones") {
      this.menu.promociones = true;
      await this.restorePromotion();
    }
  }

  async getConfiguration() {
    await this.webService.getConfiguracion().then(async (data: any) => {
      if (!data.error) {
        if (data[0]) {
          this.configuracion = data[0];
          //  //console.log("Configuracion", this.configuracion);
        } else {
          //console.log("No se ha encontrado configuracion");
        }
      } else {
        this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  async getInformation() {
    await this.webService.getInformacion().then(async (data: any) => {
      if (!data.error) {
        if (data[0]) {
          this.information = data[0];
          //console.log("Informacion", this.information);
        } else {
          //console.log("No se ha encontrado information");
        }
      } else {
        this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });

    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.getDataCompany(url).then((res: any) => {
        if (!res.error) {
          this.location.latitud = res.latitude;
          this.location.longitud = res.longitude;
        }
      });
    });
  }

  async getImagesBanner() {
    await this.webService.getImagesBanner().then(async (data: any) => {
      if (!data.error) {
        this.imagesBanner = data;
        // //console.log("Banner", this.imagesBanner);
      } else {
        this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  async getRoomsTypes() {
    await this.webService.getUrlEmpresa().then(async (url_billing) => {
      if (url_billing) {
        this.webService.getRoomTypesSerivice(url_billing).then(async (restypes: any) => {
          if (!restypes.error) {
            if (restypes.rta == true) {
              this.typesRooms = restypes.data;
              //console.log("typesRooms", this.typesRooms);
            } else {
              this.toaster.warning('No se ha encontrado resultados', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          } else {
            this.toaster.error('Error al conectar con el servidor', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        });
      } else {
        //console.log("No se ha encontrado la url");
      }
    });
  }

  // async ModalUploadFile(uploadFileModal, type, other) {
  //   this.modalCtrl.open(uploadFileModal, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then(async (result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //     await this.uploadFileDrive(result, type, other);
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }

  // async ModalUploadFile(uploadFileModal, type, other) {
  //   // //console.log('obj', other);
  //   //console.log('uploadFileModal',uploadFileModal);
    
  //   this.modalCtrl.open(uploadFileModal, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then(async (result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //     //console.log(result);

  //     // await this.uploadFileDrive(result, type, other);
  //     await this.uploadFileFirebase(result, type, other);
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }
  async ModalUploadFile(uploadFileModal, type, other) {
    // //console.log('obj', other);

    this.modalCtrl.open(uploadFileModal, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      // //console.log(result);

      // await this.uploadFileDrive(result, type, other);
      await this.uploadFileFirebase(result, type, other);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async updateColors(colorFondo, colorTexto) {
    if (colorFondo || colorTexto) {
      this.configuracion.colorPrincipal = colorFondo;
      this.configuracion.colorLetra = colorTexto;
      this.loading = true;
      await this.webService.updateConfiguracion(this.configuracion).then(async (data) => {
        this.loading = false;
        await this.getConfiguration();
        this.toaster.success('Colores actualizados exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      });
    } else {
      this.toaster.error('Seleccione un color', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async updateConfiguration(configuration) {
    this.loading = true;
    await this.webService.updateConfiguracion(configuration).then(async (data) => {
      await this.getConfiguration();
      this.toaster.success('Información actualizada exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    });
    this.loading = false;
  }

  // Funcion general para subir imagen al drive
  async uploadFileDrive(archive, type, other) {

    let drive;
    const file: File = archive.files[0];

    if (file) {

      this.loading = true;

      if (type == 'Logo') {
        drive = this.configuracion.urlLogo;
      }

      if (type == 'AboutUs') {
        drive = this.configuracion.urlLogo;
      }

      if (type == 'Banner') {
        drive = this.configuracion.urlBanner;
      }

      if (type == 'TypesRooms') {
        drive = this.configuracion.urlProductos;
      }

      if (type == 'Room_1' || type == 'Room_2' || type == 'Room_3') {
        drive = this.configuracion.urlProductos;
      }

      if (type == 'promotion') {
        drive = this.configuracion.urlPromocion;
      }

      //console.log('drive', drive);
      

      const reader = new FileReader();
      let fileInfo = new FileInfo();
      fileInfo.Name = file.name;
      fileInfo.Blob = file;

      await this.appContext.Repository.File.importFile(drive, fileInfo,
       
        
        (res) => this.onImportError(res),
        (res) => this.onCompleteUpload(res, type, other),
        (res) => this.onImportProgress(res)
        
      )
      //console.log('type',type);
      //console.log('other',other);
      // //console.log('res',res);

    } else {
      this.toaster.error('Seleccione una imagen, por favor.', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }

  }

  async onCompleteUpload(res, type, other) {

    //console.log("res", res);

    if (type == 'Logo') {
      await this.updateImageLogo(res);
    }

    if (type == 'AboutUs') {
      await this.createUpdateAboutUs(res, type, other);
    }

    if (type == 'Banner') {
      let nameGeneric = 'banner'
      await this.insertImagenBanner(res, nameGeneric);
    }

    if (type == 'TypesRooms') {
      //console.log('ENTRA ==== > TypesRooms');
      
      await this.updateImageTypeRoom(res, type, other);
    }

    if (type == 'Room_1') {
      let img = 'imagen_uno';
      await this.updateImageRoom(res, img, other);
    }
    if (type == 'Room_2') {
      let img = 'imagen_dos';
      await this.updateImageRoom(res, img, other);
    }
    if (type == 'Room_3') {
      let img = 'imagen_tres';
      await this.updateImageRoom(res, img, other);
    }

    if (type == 'Promotion') {
      //console.log('ESTA ENTRANDO');
      
      // await this.insertPromotion(res, other);
      await this.insertPromotionWeb(res, other);

    }

    this.loading = false;

  }

  async updateImageLogo(res) {

    this.configuracion.imgLogo = res;
    await this.webService.updateConfiguracion(this.configuracion).then(async (data) => {
      await this.getConfiguration();
      this.toaster.success('La Imagen ha sido actualizada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    });
  }

  async createUpdateAboutUs(res, type, other) {
    this.loading = true;
    // //console.log('res', res);

    // let data = JSON.parse(res);
    let data  = res;
    // let imageDelet;

    if (other == 'history') {
      // imageDelet = this.information.imagenHistoria;
      this.information.imagenHistoria = data;
      await this.updateAboutUs(this.information);
    }
    // if (other == 'history') {
    //   imageDelet = this.information.imagenHistoria;
    //   this.information.imagenHistoria = data.id;
    //   await this.updateAboutUs(this.information, imageDelet);
    // }

    if (other == 'mision') {
      // imageDelet = this.information.imagenMision;
      this.information.imagenMision = data.id;
      await this.updateAboutUs(this.information);
      // await this.updateAboutUs(this.information, imageDelet);
    }

    if (other == 'vision') {
      // imageDelet = this.information.imagenVision;
      this.information.imagenVision = data.id;
      await this.updateAboutUs(this.information);
    }

    if (other == 'valores') {
      // imageDelet = this.information.imagenValores;
      this.information.imagenValores = data.id;
      await this.updateAboutUs(this.information);
    }

    this.loading = false;
  }
  // async createUpdateAboutUs(res, type, other) {
  //   this.loading = true;
  //   let data = JSON.parse(res);
  //   let imageDelet;

  //   if (other == 'history') {
  //     imageDelet = this.information.imagenHistoria;
  //     this.information.imagenHistoria = data.id;
  //     await this.updateAboutUs(this.information, imageDelet);
  //   }

  //   if (other == 'mision') {
  //     imageDelet = this.information.imagenMision;
  //     this.information.imagenMision = data.id;
  //     await this.updateAboutUs(this.information, imageDelet);
  //   }

  //   if (other == 'vision') {
  //     imageDelet = this.information.imagenVision;
  //     this.information.imagenVision = data.id;
  //     await this.updateAboutUs(this.information, imageDelet);
  //   }

  //   if (other == 'valores') {
  //     imageDelet = this.information.imagenValores;
  //     this.information.imagenValores = data.id;
  //     await this.updateAboutUs(this.information, imageDelet);
  //   }

  //   this.loading = false;
  // }
  async updateAboutUs(information) {
    this.information = information;
    // //console.log('llega a este punto y debe actualizarse');

    await this.updateInformation(information);
    // if (imageDelet) {
    //   this.appContext.Repository.File.delete(imageDelet).then(async (resDrive) => {
    //   });
    // }
  }
  // async updateAboutUs(information, imageDelet) {
  //   await this.updateInformation(information);
  //   if (imageDelet) {
  //     this.appContext.Repository.File.delete(imageDelet).then(async (resDrive) => {
  //     });
  //   }
  // }

  async updateInformation(information) {
    this.loading = true;
    await this.webService.updateInformation(information).then(async (data) => {
      if (this.location.update == true) {
        await this.webService.getUrlEmpresa().then(async (url) => {
          await this.webService.updateLocationCompany(url, this.location.latitud, this.location.longitud).then((resupd) => { });
        });
      }
      await this.getInformation();
      this.toaster.success('Información actualizada exitosamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    });
    this.loading = false;
  }
  async insertImagenBanner(res, name) {
    // let data = JSON.parse(res);
    // let url = data.id;
    let datos = {
      nombre: name,
      url: res
    }
    this.webService.insertImageBanner(datos).then((data: any) => {
      // //console.log('resp guardar imagen', data);
      if (!data.error) {
        this.getImagesBanner();
        this.toaster.success('Banner insertado exitoramente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      } else {
        // this.appContext.Repository.File.delete(res).then(async (resDrive) => { //console.log(resDrive); });
        this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }
  // async insertImagenBanner(res) {
  //   let data = JSON.parse(res);
  //   let url = data.id;
  //   let datos = {
  //     nombre: data.name,
  //     url: url
  //   }
  //   this.webService.insertImageBanner(datos).then((data: any) => {
  //     //console.log('resp guardar imagen', data);
  //     if (!data.error) {
  //       this.getImagesBanner();
  //       this.toaster.success('Banner insertado exitoramente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
  //     } else {
  //       this.appContext.Repository.File.delete(url).then(async (resDrive) => { //console.log(resDrive); });
  //       this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
  //     }
  //   });
  // }

  // async deleteBanner(banner) {
  //   this.loading = true;
  //   await this.webService.deleteImageBanner(banner.id_img_banner).then((resdel: any) => {
  //     if (!resdel.error) {
  //       this.getImagesBanner();
  //       this.appContext.Repository.File.delete(banner.url).then(async (resDrive) => { });
  //     } else {
  //       this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
  //     }
  //   });
  //   this.loading = false;
  // }
  async deleteBanner(banner) {

    // //console.log('banner que recibo', banner);

    this.loading = true;
    await this.webService.deleteImageBanner(banner.id_img_banner).then((resdel: any) => {
      if (!resdel.error) {
        this.getImagesBanner();
        //console.log('banner', banner.url);
        
        // this.appContext.Repository.File.delete(banner.url).then(async (resDrive) => { });
        this.deleteFileFirebase(banner.url);

      } else {
        this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
    this.loading = false;
  }

  async deletePromotionWeb(promotion) {


    await this.webService.deletePromotionWeb(promotion.id_promocion).then(async (resdel: any) => {
      if (!resdel.error) {
        await this.getPromotionsWeb();
        this.toaster.success('Promoción eliminada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        // this.appContext.Repository.File.delete(promotion.imgPromocion).then(async (resDrive) => { });
        this.deleteFileFirebase(promotion.imgPromocion);
      } else {
        this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  // async deletePromotionWeb(promotion) {


  //   await this.webService.deletePromotionWeb(promotion.id_promocion).then(async (resdel: any) => {
  //     if (!resdel.error) {
  //       await this.getPromotionsWeb();
  //       this.toaster.success('Promoción eliminada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
  //       // this.appContext.Repository.File.delete(promotion.imgPromocion).then(async (resDrive) => { });
  //       this.deleteFileFirebase(promotion.imgPromocion);
  //     } else {
  //       this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
  //     }
  //   });
  // }


  async updateImageTypeRoom(res, type, other) {
    //console.log('res', res);
    
    // let data = JSON.parse(res);
    let data = (res);
    await this.webService.getUrlEmpresa().then(async (url) => {
      if (url) {
        let datos = {
          tabla: 'tipo_habitacion',
          id: 'id_tiphab',
          valor_id: other.id_tiphab,
          atributo: 'img',
          valor_atributo: data
        }
        //console.log('datos == >', datos);
        
        let imageDelet = other.img;
        await this.webService.updateAttributeTable(url, datos).then(async (resupd: any) => {
          if (resupd.rta == true) {
            this.toaster.success('La Imagen ha sido actualizada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            await this.getRoomsTypes();
            if (imageDelet) {
              // this.appContext.Repository.File.delete(imageDelet).then(async (resDrive) => { });
            }
          } else {
            this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            // this.appContext.Repository.File.delete(data.id).then(async (resDrive) => { });
          }
        });
      } else {
        //console.log("no se pudo obter url empresa");
      }
    });
    await this.getRoomsTypes();
  }

  async updateDescriptionTypeRoom(typeRoom) {
    let datos = {
      tabla: 'tipo_habitacion',
      id: 'id_tiphab',
      valor_id: typeRoom.id_tiphab,
      atributo: 'descripcion',
      valor_atributo: typeRoom.descripcion
    }
    this.loading = true;
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.updateAttributeTable(url, datos).then(async (resupd: any) => {
        if (resupd.rta == true) {
          this.toaster.success('La descripción ha sido actualizada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        } else {
          this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    });
    this.loading = false;
  }

  async updateImageRoom(res, img, other) {

    // let data = JSON.parse(res);
    let data =(res);
    await this.webService.getUrlEmpresa().then(async (url) => {
      if (url) {
        let imageDelet;

        let datos = {
          tabla: 'habitacion',
          id: 'id_hab',
          valor_id: other.id_hab,
          atributo: img,
          valor_atributo: data
        }

        //console.log('datos', datos);
        

        if (img == 'imagen_uno') {
          imageDelet = other.imagen_uno;
        }
        if (img == 'imagen_dos') {
          imageDelet = other.imagen_dos;
        }
        if (img == 'imagen_tres') {
          imageDelet = other.imagen_tres;
        }

        await this.webService.updateAttributeTable(url, datos).then(async (resupd: any) => {
          if (resupd.rta == true) {
            if (img == 'imagen_uno') {
              other.imagen_uno = data.id;
            }
            if (img == 'imagen_dos') {
              other.imagen_dos = data.id;
            }
            if (img == 'imagen_tres') {
              other.imagen_tres = data.id;
            }
            this.toaster.success('La Imagen ha sido actualizada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            this.selectRoomType(this.typeRoomSelect);

            if (imageDelet) {
              // this.appContext.Repository.File.delete(imageDelet).then(async (resDrive) => { });
            }
          } else {
            this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            // this.appContext.Repository.File.delete(data.id).then(async (resDrive) => { });
          }
          // await this.getRoomsTypes();
          // //console.log('this.typeRoomSelect',this.typeRoomSelect);
          
     
          // this.rooms()
        });

      } else {
        //console.log("no se pudo obter url empresa");
      }
    });
  }

  async updateDescriptionRoom(r) {
    let datos = {
      tabla: 'habitacion',
      id: 'id_hab',
      valor_id: r.id_hab,
      atributo: 'descripcion',
      valor_atributo: r.descripcion
    }

    this.loading = true;
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.updateAttributeTable(url, datos).then(async (resupd: any) => {
        if (resupd.rta == true) {
          this.toaster.success('La descripción ha sido actualizada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        } else {
          this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    });
    this.loading = false;
  }

  async insertPromotionWeb(res, other) {
    // let data = JSON.parse(res);
    let data = res;
    let query = {
      "nombrePromocion": other.title,
      "descripcion": other.description,
      "id_producto": other.id_producto,
      "nombreImagen": 'promocion',
      "imgPromocion": data
    }

    //console.log('query',query);
    

    await this.webService.insertPromotionWeb(query).then(async (respromo: any) => {
      if (!respromo.error) {
        await this.getPromotionsWeb();
        this.toaster.success('Promoción creada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      } else {
        this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  onImportError(res) {
    this.loading = false;
    //console.log("error", res)
    this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
  };

  onImportProgress(res) {
    // //console.log("proceso", res)
  };

  // async ModaldeleteFile(deleteFileModal, data, type) {
  //   this.modalCtrl.open(deleteFileModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //     if (type == "Banner") {
  //       await this.deleteBanner(data);
  //     }
  //     if (type = 'Promotion') {
  //       await this.deletePromotion(data);
  //     }
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }
  async ModaldeleteFile(deleteFileModal, data, type) {
    this.modalCtrl.open(deleteFileModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      if (type == "Banner") {
        await this.deleteBanner(data);
      }
      if (type == 'Promotion') {
        await this.deletePromotionWeb(data);
      }
      // if (type == 'Color') {
      //   await this.deleteColorTable(data);
      // }
      // if (type == 'talla_guia_detalle') {
      //   await this.deleteGuiaTallasDetalle(data);
      // }
      // if (type == 'talla_guia') {
      //   await this.deleteGuiaTallas(data);
      // }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  

  async viewTypesRooms(type) {

    if (this.typesRooms.length == 0) {
      await this.getRoomsTypes();
    }

    this.viewRooms = {
      types: false,
      rooms: false,
    }

    if (type == 'Types') {
      this.viewRooms.types = true;
    }

    if (type == 'Rooms') {
      this.viewRooms.rooms = true;
      let event = {
        target: {
          value: this.typesRooms[0]?.id_tiphab
        }
      }
      this.selectRoomType(event);
    }
    await this.selectMenu('imagenesHabitaciones');
  }

  async selectRoomType(event) {
    let typeRoom = {
      id_tiphab: event.target.value
    }
    this.typeRoomSelect = event
    this.loading = true;
    await this.webService.getUrlEmpresa().then(async (url_billing) => {
      if (url_billing) {
        await this.webService.getRoomsSerivice(url_billing, typeRoom).then(async (resroom: any) => {
          if (!resroom.error) {
            if (resroom.rta == true) {
           
              this.rooms = resroom.data;
              //console.log("yeahhhh", this.rooms);
              this.newPromotion.viewRoom = true;
            } else {
              this.rooms = resroom.data;
              this.toaster.warning('No se ha encontrado resultados', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              this.newPromotion.viewRoom = false;
            }
          } else {
            this.toaster.error('Error al conectar con el servidor', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        });
      } else {
        //console.log("No se ha encontrado la url");
      }
    });
    this.loading = false;
  }

  async updateCredentials(updCredentials) {
    if (updCredentials.currentPassword && updCredentials.newPassword) {
      if (updCredentials.password == updCredentials.currentPassword) {
        this.loading = true;
        let login = {
          usuario: updCredentials.user,
          clave: updCredentials.password
        }
        await this.webService.loginAdministrator(login).then(async (reslog: any) => {
          if (reslog.rta == true) {
            reslog.data.password = updCredentials.newPassword;
            await this.webService.updateAdministrator(reslog.data).then(async (resupd: any) => {
              if (!resupd.error) {
                this.webService.saveClientLocalStorage(reslog.data, this.configuracion.loginStorage, 'Administrator').then(async (resLocal: any) => {
                  this.toaster.success('Contraseña actualizada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                  await this.verifyLoginClient();
                  this.updCredentials.currentPassword = '';
                  this.updCredentials.newPassword = '';
                });
              } else {
                this.toaster.error('Ha ocurrido un error, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              }
            });
          }
        });

        this.loading = false;
      } else {
        this.updCredentials.currentPassword = '';
        this.updCredentials.newPassword = '';
        this.toaster.error('Las contraseñas no coinciden, intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    } else {
      this.toaster.error('Campos vacios', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async goHome() {
    await this.webService.goHome();
  }

  async signOff() {
    await this.webService.signOuth(this.configuracion.loginStorage).then(async (resClose: any) => {
      this.goHome();
    });
  }

  async selectRoomTypePromotion(event) {
    await this.selectRoomType(event);
  }

  async selectRoomPromotion(event) {
    this.newPromotion.id_producto = event.target.value;
  }

  async createPromotion(image, promotion) {
    //console.log('Promotion', promotion);
    
    
    if (promotion.title && promotion.description && image.files[0]) {
       await this.uploadFileFirebase(image, 'Promotion', promotion);

    } else {
      this.toaster.error('Campos vacios, verifique e intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }
  // async modalcreatePromotion(createPromotionModal,promotion) {
  //   // this.newPromotion = {
  //   //   id_producto: 0,
  //   // };
  //   this.modalCtrl.open(createPromotionModal, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then(async (result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //     // //console.log("imagen", result);
  //     const file: File = result.files[0];
  //     if (file && promotion.description) {
  //       // await this.uploadFileDrive(result, 'Promotion', this.newPromotion);
  //      await this.uploadFileFirebase(result, 'Promotion', promotion);

  //     } else {
  //       this.toaster.error('Campos obligatorios vacios, verifique e intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
  //     }
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }

  

  async getPromotion() {
    this.loading = true;
    await this.webService.getPromotion().then((respromo: any) => {
      this.promotions = respromo.data;
      //console.log('promotions', this.promotions);
      
    });
    this.loading = false;
  }

  async insertPromotion(res, promotion) {
    // //console.log('res',res);
    
    // let data = JSON.parse(res);
    let promo = {
      nombrePromocion: promotion.title,
      descripcion: promotion.description,
      id_producto: promotion.id_producto,
      nombreImagen: res.name,
      imgPromocion: res.id
    }

    //console.log(promo);
    

    // await this.webService.insertPromotion(promo).then(async (data) => {
    //   this.toaster.success('Promoción creada exitosamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    //   await this.getPromotion();
    //   await this.restorePromotion();
    // });

  }

  async deletePromotion(promotion) {
    this.loading = true;
    await this.webService.deletePromotion(promotion.id_promocion).then(async (resdel: any) => {
      await this.getPromotion()
      this.appContext.Repository.File.delete(promotion.imgPromocion).then(async (resDrive) => { });
    });
    this.loading = false;
  }

  async restorePromotion() {
    this.newPromotion = {
      title: '',
      description: '',
      image: '',
      id_producto: '',
      viewRoom: false
    }
  }

  async editLocation(e) {
    this.location.update = true;
  }

  async getRoomForPerson() {
    await this.webService.getPermitRoomForPerson().then((res) => {
      if (res.rta == true) {
        this.room_for_person = res.data.valor;
      }
    });
  }

  async updateRoomForPerson(value, configuracion){
    configuracion.habitacion_por_persona = value;
    await this.updateConfiguration(this.configuracion);
  }

  async uploadFileFirebase(archive, type, other){

    //console.log('type', type);

    let fireBase;
    let bd;
    const file: File = archive.files[0];
    let pathFB;
    let rutaBD;
    if (file) {
      this.loading = true;
      if (type == 'Logo') {
        fireBase = this.urlLogo_sobre_nosotros_gruposFB;
         bd = this.urlLogo_sobre_nosotros_grupos;
         let nameLogo = this.generateRandomString();
         pathFB = this.empresa+'/'+fireBase+'/'+'logoGeneral'+nameLogo;
         rutaBD = bd+'logoGeneral'+nameLogo;
         this.deleteFileFirebase(other.imgLogo);
         this.uploadToFirebase(file ,pathFB,rutaBD, type, other);
      }

      if (type == 'AboutUs') {
        fireBase = this.urlLogo_sobre_nosotros_gruposFB;
        bd = this.urlLogo_sobre_nosotros_grupos;
        let nameH = this.generateRandomString();
        pathFB = this.empresa+'/'+fireBase+'/'+'historiaGeneral'+nameH;
        rutaBD = bd+'historiaGeneral'+nameH;
        this.uploadToFirebase(file ,pathFB,rutaBD, type, other);
      }

      if (type == 'Banner') {
        //console.log('ENTRA A BANNER');
        // //console.log('Archive', archive);
        // //console.log('type', type);
        //console.log('file', file);
        
        fireBase = this.urlBannerFB;
        bd = this.urlBanner;
        // generateRandomString
        let nameBanner = this.generateRandomString()
        pathFB = this.empresa+'/'+fireBase+'/'+nameBanner
        rutaBD = bd+nameBanner
        this.uploadToFirebase(file ,pathFB,rutaBD, type, other);
      }

      if (type == 'Groups') {
        fireBase = this.urlLogo_sobre_nosotros_gruposFB;
        bd = this.urlLogo_sobre_nosotros_grupos;
        let nameGrupo = this.generateRandomString()
        pathFB = this.empresa+'/'+fireBase+'/'+'Grupo'+other.idgrupo+'_'+nameGrupo;
        rutaBD = bd+'Grupo'+other.idgrupo+'_'+nameGrupo;
        // Delete Before IMG
        this.deleteFileFirebase(other.img);
        // Compress new Img
        let fileCompress;
        await this.fireService.compressFile(file).then(async(res:any)=>{
          fileCompress = await res;
        });

        // Upload new Image Compress
        this.uploadToFirebase(fileCompress ,pathFB,rutaBD, type, other);

      }

      if (type == 'Subgroups') {
        fireBase = this.urlLogo_sobre_nosotros_gruposFB;
        bd = this.urlLogo_sobre_nosotros_grupos;
        let nameSubGrupo = this.generateRandomString()
        pathFB = this.empresa+'/'+fireBase+'/'+'subgrupo'+other.id_sub+'_'+nameSubGrupo;
        rutaBD = bd+'subgrupo'+other.id_sub+'_'+nameSubGrupo;
        this.deleteFileFirebase(other.img);
        let fileCompress;
        await this.fireService.compressFile(file).then(async(res:any)=>{
          fileCompress = await res;
        });

        // Upload new Image Compress
        this.uploadToFirebase(fileCompress ,pathFB,rutaBD, type, other);
      }

      if (type == 'Room_1' || type == 'Room_2' || type == 'Room_3' || type == 'Room_4') {
        fireBase = this.urlProductosFB;
        bd = this.urlProductos;
        let nameProducto = this.generateRandomString()
        pathFB = this.empresa+'/'+fireBase+'/'+'producto'+other.codigo+'_'+type+nameProducto;
        rutaBD = bd+'producto'+other.codigo+'_'+type+nameProducto;
        if(type == 'Room_1' ){
          this.deleteFileFirebase(other.imagen_uno);
          let fileCompress;
          await this.fireService.compressFile(file).then(async(res:any)=>{
            fileCompress = await res;
          });

          // Upload new Image Compress
          this.uploadToFirebase(fileCompress ,pathFB,rutaBD, type, other);
        }else if(type == 'Room_2'){
          this.deleteFileFirebase(other.imagen_dos);
          let fileCompress;
          await this.fireService.compressFile(file).then(async(res:any)=>{
            fileCompress = await res;
          });

          // Upload new Image Compress
          this.uploadToFirebase(fileCompress ,pathFB,rutaBD, type, other);
        }else if(type == 'Room_3'){
          this.deleteFileFirebase(other.imagen_tres);
          let fileCompress;
          await this.fireService.compressFile(file).then(async(res:any)=>{
            fileCompress = await res;
          });

          // Upload new Image Compress
          this.uploadToFirebase(fileCompress ,pathFB,rutaBD, type, other);
        }else{
          this.deleteFileFirebase(other.imagen_cuatro);
          let fileCompress;
          await this.fireService.compressFile(file).then(async(res:any)=>{
            fileCompress = await res;
          });

          // Upload new Image Compress
          this.uploadToFirebase(fileCompress ,pathFB,rutaBD, type, other);
        }

      }

      if (type == 'Promotion') {
        // //console.log("ENTRA A ESTE CASO");
        
        fireBase = this.urlPromocionesFB;
        bd = this.urlPromociones;
        let namePromo = this.generateRandomString();
        pathFB = this.empresa+'/'+fireBase+'/'+'promocion'+namePromo
        rutaBD = bd+'promocion'+namePromo
        // this.deleteFileFirebase(other.imgFooter);
        let fileCompress;
        await this.fireService.compressFile(file).then(async(res:any)=>{
          fileCompress = await res;

        });

        //console.log('OTHER OTHER', other);
        
        other.image=rutaBD 

        this.uploadToFirebase(fileCompress ,pathFB,rutaBD, type, other);
        // await this.insertPromotion(fileCompress, other);
          // this.onCompleteUpload(rutaBD, type, other),

       
      }

      if (type == 'Footer') {
        fireBase = this.urlLogo_sobre_nosotros_gruposFB;
        bd = this.urlLogo_sobre_nosotros_grupos;
        let nameLogo = this.generateRandomString();
        pathFB = this.empresa+'/'+fireBase+'/'+'portadaGeneral'+nameLogo
        rutaBD = bd+'portadaGeneral'+nameLogo
        this.deleteFileFirebase(other.imgFooter);
        this.uploadToFirebase(file ,pathFB,rutaBD, type, other);

      }
      
      if (type == 'TypesRooms') {
        //console.log('ENTRA A TYPESROOMS');
        
        fireBase = this.urlHabitacionesFB;
        bd = this.urlHabitaciones;
        let nameLogo = this.generateRandomString();
        pathFB = this.empresa+'/'+fireBase+'/'+'habitaciones'+nameLogo
        rutaBD = bd+'habitaciones'+nameLogo
        // this.deleteFileFirebase(other.imgFooter);
        this.uploadToFirebase(file ,pathFB,rutaBD, type, other);

      }



    //  await this.fireService.uploadLogoFirebase( file ,pathFB).then((data:any)=>{
    //     // //console.log(data);
    //     this.onCompleteUpload(rutaBD, type, other),
    //     // this.toaster.success('Se ha cambiado el logo correctamente','Logo Cambiado')
    //     this.loading = false;
    //   });


    } else {
      this.toaster.error('Seleccione una imagen, por favor.', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }

  async uploadToFirebase(file, pathFB, rutaBD, type, other){
    // //console.log();
    // //console.log('type',type);
    // //console.log('other',other);
    
    await this.fireService.uploadLogoFirebase( file ,pathFB).then((data:any)=>{
      this.onCompleteUpload(rutaBD, type, other),
      // this.toaster.success('Se ha cambiado el logo correctamente','Logo Cambiado')
      this.loading = false;
    });
  }
  async deleteFileFirebase(path) {
    //console.log('path', path);
    
    const storageRef = firebase.storage().ref();
    // const storageRef = firebase.storage().ref()
    const desertRef = `${this.sistema}/${path}`
    const nuevaCadena = desertRef.replace('%2F', '/');
    const referenciaFireBase = storageRef.child(nuevaCadena);
    // Delete the file
   await referenciaFireBase.delete().then(() => {
    }).catch((error) => {
      // Uh-oh, an error occurred!
      // alert('Uh-oh, an error occurred!')
    });
  }

  generateRandomString() {
    let letters = 'abcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < 5; i++) {
      let randomIndex = Math.floor(Math.random() * letters.length);
      result += letters[randomIndex];
    }
    return result;

  }

  async getPromotionsWeb() {
    this.loading = true;
    await this.webService.getPromocionesWeb().then(async (resprom: any) => {
      if (resprom.length > 0) {
        for (let p of resprom) {
          if (p.id_producto != 0 && p.id_producto) {
            // //console.log("Siii se va", p.id_producto);
            await this.webService.getUrlEmpresa().then(async (url) => {
              await this.webService.getProductosCodigoService(url, p.id_producto, this.configuracion).then(async (resprod: any) => {
                if (resprod.rta == true) {
                  p.nombre_producto = p.id_producto + ' / ' + resprod.data[0].nombre_producto;
                } else {
                  p.nombre_producto = p.id_producto + ' / No encontrado';
                }
              });
            });
          } else {
            // //console.log("Nooo se va", p.id_producto);
            p.nombre_producto = 'No asignado';
          }
        }
      }
      this.promotions = resprom;
    });
    this.loading = false;
  }



}
