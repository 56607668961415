import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ServicesService } from '../../../shared/services/services.service';

@Component({
  selector: 'app-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.scss']
})
export class LoginUserComponent implements OnInit {

  @ViewChild("modalRegisterClient", {}) registerClientModal: TemplateRef<any>;
  @ViewChild("modalRecoverPassword", {}) recoverPasswordModal: TemplateRef<any>;

  public closeResult: string;
  public loadingAll = false;
  public empresa;
  public configuracion;
  public login = {
    usuario: '',
    clave: '',
    viewPass: true
  }
  public newClient = {
    cedula: '',
    nombres: '',
    apellidos: '',
    razonsocial: '',
    direccion: '',
    telefonos: '',
    email: '',
    celular: '',
    tipoCli: '1',
    vendedor_id: '',
    diasCredito: '0',
    cupo_credito: '0',
    es_pasaporte: 0
  }
  public recover = {
    cedula: '',
    correo: ''
  }

  constructor(
    private modalCtrl: NgbModal,
    private webService: ServicesService,
    private toaster: ToastrService,
  ) { }

  async ngOnInit() {
    this.loadingAll = true;
    await this.getConfiguracion();
    this.loadingAll = false;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === ModalDismissReasons) {
      return `with: ${reason}`;
    }
  }

  async getConfiguracion() {
    await this.webService.getConfiguracion().then(async (dataConf: any) => {
      this.configuracion = dataConf[0];

      let bl = document.getElementById('btn-login');
      bl.style.background = this.configuracion.colorPrincipal;
      bl.style.color = this.configuracion.colorLetra;

      let br = document.getElementById('btn-register');
      br.style.background = this.configuracion.colorPrincipal;
      br.style.color = this.configuracion.colorLetra;
    });
  }

  async dismissModal(data) {
    this.modalCtrl.dismissAll(data);
    if (data == 'Login') {
      // console.log("logear cliente");
      if (this.login.usuario && this.login.clave) {
        this.toaster.info('Espere un momento ...', '', { timeOut: 1000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        await this.webService.getUrlEmpresa().then(async (url_billing) => {
          await this.loginClient(url_billing, this.configuracion, this.login, 'Login');
        });
      } else {
        this.toaster.warning('Ingrese sus credenciales', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    }

    if (data == 'Registrar') {
      // console.log("registrar asdasdas");
      await this.modalRegisterClient();
    }

    if (data == 'Recover') {
      // console.log("recover password");
      await this.modalRecoverPassword();
    }

  }

  async loginClient(url_billing, configuracion, login, type) {
    await this.webService.loginClient(url_billing, login).then(async (resClient: any) => {
      // console.log("ress log", resClient);
      let empresa = '';
      await this.webService.getInformacion().then((resinfo) =>{
        empresa = resinfo[0].nombre;
      });
      
      if (resClient) {
        resClient.cedula = resClient.PersonaComercio_cedulaRuc;
        await this.webService.saveClientLocalStorage(resClient, configuracion.loginStorage, "Client").then((resLocal: any) => {
          if(type == 'Login'){
            this.toaster.success('A ' + empresa, 'Bienvenid@, ' + resClient.nombres , { timeOut: 5000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
          if(type == 'Register'){
            this.toaster.success('Registro realizado exitosamente, la información ha sido enviada a su correo', 'Bienvenid@, ' + resClient.nombres, { timeOut: 5000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        });
      } else {
        this.toaster.warning('Usuario no encontrado, Registrelo.', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        this.dismissModal('Registrar');
      }
    });
  }

  async modalRegisterClient() {
    this.modalCtrl.open(this.registerClientModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      let url_billing;
      let client = result;
      if (client.cedula && client.nombres && client.apellidos && client.email) {
        this.toaster.info('Espere un momento ...', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        await this.webService.getUrlEmpresa().then(async (url) => {
          url_billing = url;
        });
        if (client.cedula.length <= 13) {
          await this.webService.validateIdentyDocument(url_billing, client.cedula).then(async (resValid: any) => {
            if (!resValid.error) {
              if (this.webService.validateEmail(client.email) == true) {
                client.nombres = this.webService.convertMayuscula(client.nombres);
                client.apellidos = this.webService.convertMayuscula(client.apellidos);
                client.razonsocial = client.nombres + ' ' + client.apellidos;
                if (resValid.valor == 0) {
                  client.es_pasaporte = 1;
                }
                this.webService.registerClientBilling(url_billing, client).then(async (resRegist: any) => {
                  if (!resValid.error) {
                    if (resRegist.res.rta == 1) {
                      let data = {
                        nombres: client.nombres,
                        apellidos: client.apellidos,
                        cedula: client.cedula,
                        email: client.email
                      }
                      await this.webService.createBodyMailRegister(this.configuracion, data).then(async (resbody) => {
                        await this.webService.sendMailService(resbody).then((resmail: any) => {});
                      });
                      let login = {
                        usuario: client.cedula,
                        clave: client.cedula
                      }
                      await this.loginClient(url_billing, this.configuracion, login, 'Register');
                    } else {
                      this.toaster.warning('El cliente ya se encuentra regitrado, Inicie sesión', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                    }
                  } else {
                    this.toaster.error('Error al conectar con el servidos, vualva a intentarlo', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                  }
                });
              } else {
                this.toaster.error('Correo electrónico no válido', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                this.dismissModal('Registrar');
              }
            } else {
              this.toaster.error('Error al conectar con el servidos, vualva a intentarlo', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          });
        } else {
          this.toaster.error('El número de idenficación no puede poseer más de 13 caracteres.', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          this.dismissModal('Registrar');
        }
      } else {
        this.toaster.error('Campos vacios, ingrese su información', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        this.dismissModal('Registrar');
      }

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log(reason);
    });
  }

  async modalRecoverPassword() {
    let userPasword = '';
    let dataUpdate: any;
    this.modalCtrl.open(this.recoverPasswordModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      this.toaster.info('Espere un momento ...', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      if (this.recover.cedula && this.recover.correo) {
        if (this.webService.validateEmail(this.recover.correo) == true) {
          await this.webService.getUrlEmpresa().then(async (url_billing) => {
            await this.webService.validateClientCedIdentity(url_billing, this.recover).then(async (resvalid: any) => {
              if (!resvalid.error) {
                if (resvalid.rta == true) {
                  if (resvalid.data.clave) {
                    userPasword = resvalid.data.clave;
                  } else {
                    userPasword = resvalid.data.PersonaComercio_cedulaRuc;
                    dataUpdate = {
                      tabla: 'billing_cliente',
                      id: 'PersonaComercio_cedulaRuc',
                      valor_id: resvalid.data.PersonaComercio_cedulaRuc,
                      atributo: 'clave',
                      valor_atributo: userPasword
                    }
                  }
                  let data = {
                    nombres: resvalid.data.nombres,
                    apellidos: resvalid.data.apellidos,
                    cedula: userPasword,
                    email: this.recover.correo
                  }
                  await this.webService.createBodyMailRecoverPassword(this.configuracion, data).then(async (resbody) => {
                    await this.webService.sendMailService(resbody).then(async (resmail: any) => {
                      if (resmail.rta == true) {
                        if (!resvalid.data.clave) {
                          await this.webService.updateAttributeTable(url_billing, dataUpdate).then((resUpd: any) => {
                            if (!resUpd.error) {
                              this.toaster.success('La contraseña ha sido envida a su correo', 'Envío exitoso', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                            } else {
                              this.toaster.error('Intente nuevamente', 'Error en actualización', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                            }
                          });
                        } else {
                          this.toaster.success('La contraseña ha sido envida a su correo', 'Envío exitoso', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                        }
                      } else {
                        this.toaster.error('Intente nuevamente', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                      }
                    });
                  });
                } else {
                  this.toaster.warning('Datos incorrectos, verifique e intente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
                }
              } else {
                this.toaster.error('Intente nuevamente', 'Error', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              }
            });
          });
        } else {
          this.toaster.warning('Formato de Correo electrónico no válido', ' ', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      } else {
        this.toaster.warning('Ingrese su información e intente nuevamente', 'Campos vacios', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log(reason);
    });
  }

  async showInfoPass() {
    this.toaster.info('La contraseña por defecto es su Cédula o Ruc', '', { timeOut: 4000, positionClass: 'toast-center-center', closeButton: true, progressBar: true });
  }

}
