import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';

import { HomeComponent } from '../app/pages/home/home.component';
import { AdminComponent } from '../app/pages/profile/admin/admin.component';
import { UserComponent } from '../app/pages/profile/user/user.component';
import { GuardGuard } from '../app/collaborator/guards/guard.guard';
import { AboutUsComponent } from '../app/pages/about-us/about-us.component';
import { PromotionsComponent } from '../app/pages/promotions/promotions.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'inicio/:id',
    component: HomeComponent
  },
  {
    path: 'admin',
    component: AdminComponent, canActivate: [GuardGuard]
  },
  {
    path: 'user',
    component: UserComponent, canActivate: [GuardGuard]
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
  },
  {
    path: 'promotions',
    component: PromotionsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
