<app-menu></app-menu>

<ngx-loading [show]="loadingAll" [config]="{ backdropBorderRadius: '3px',  fullScreenBackdrop: true }" [template]="">
</ngx-loading>

<!-- <ngb-carousel class="banner" style="padding-top: 6em;">
    <ng-template ngbSlide *ngFor="let i of imagenBanner">
        <div class="divSelect">
            <img class="auto w-85" style="max-height: auto;" src="https://drive.google.com/uc?export=view&id={{i.url}}"
            alt="Random first slide">
        <div class="carousel-caption"></div>
        </div>

    </ng-template>
</ngb-carousel> -->


<section class="box">

  
    <div class="text-center " >
        <ngb-carousel >
            <ng-template ngbSlide *ngFor="let i of imagenBanner" >
                <img [src]="rutaUrl+i.url+'?alt=media'" alt="" class="auto imgBanner " style=" width: 100%;  " id="imgBanner"
                alt="Random first slide" >
                <img [src]="rutaUrl+i.url+'?alt=media'" alt="" class="auto imgBanner2 "  style=" width: 100%; min-height: 650px; display: none; " id="imgBanner"
                alt="Random first slide" >

                    <div class="carousel-caption " id="" style="padding-bottom: 4em;">
                        <div>
                            <h1  style="  text-shadow: black 0.1em 0.1em 0.2em; font-weight: bold; font-family: 'Montserrat', sans-serif; font-size: 3em; font-style: italic; ">BIENVENIDO A </h1>
                            <h1  style=" text-shadow: black 0.1em 0.1em 0.2em; font-weight: bold; font-family: 'Montserrat', sans-serif; ">{{companyNane | uppercase}}</h1>
                        </div>
                        
                        <p style="font-size: 1.5em; text-shadow: black 0.1em 0.1em 0.2em
                        ">El lugar perfecto para descansar</p>
                      </div>
              
            </ng-template>
        </ngb-carousel>
    </div>

</section>
<!-- <section class="home">
   
    <div id="carousel" class="carousel slide " data-ride="carousel">
    <div class="carousel-controls">
      <ol class="carousel-indicators">
        <ng-container *ngFor="let i of imagenBanner; let j = index">
            <li data-target="#carousel" data-slide-to="j"  style="background-image:url('https://drive.google.com/uc?export=view&id={{i.url}}')"></li>
        </ng-container>
       
        
      </ol>
      <a class="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
       <img src="../../../../assets/img/left-arrow.svg" alt="Prev"> 
    </a>
    <a class="carousel-control-next" href="#carousel" role="button" data-slide="next">
      <img src="../../../../assets/img/right-arrow.svg"  alt="Next">
    </a>
    </div>
    <div class="carousel-inner  ">
        <ng-container *ngFor="let i of imagenBanner">
            <div class="carousel-item active animated  bounceInRight " style="background-image:url('https://drive.google.com/uc?export=view&id={{i.url}}')">
                <div class="container">
                   <p>La facturación electrónica en Ecuador será obligatoria desde el 29 de noviembre del 2022, las personas naturales
                     y empresas están obligadas a facturar de manera electrónica.
                     </p>
                </div>
              </div>
        </ng-container>


    </div>
  </div>

   </section> -->

<section class="container-fluid" id="page-1" style="width: 85%;">

    <br>
    <div class="row text-center mt-3">
        <div class="col-lg-12">
            <h1>
                <strong style="font-family: 'Montserrat';" >
                    {{configuracion.tokenPayphone}}
                </strong>
            </h1>
            <br>
            <p class="pl-4 pr-4" style="letter-spacing: 3px; text-transform: uppercase; "> 
                {{configuracion.storeId}}
            </p>
        </div>
    </div>

   <div class="row divSelect" *ngIf="typesRooms?.length > 1">
    <div class="col-lg-4 col-md-6 p-1"  *ngFor="let g of typesRooms; let i = index">
        <div class="card card2" style=" border-radius: 14px;">
            <img [src]="rutaUrl+g.img + '?alt=media'" style="border-top-left-radius: 14px; border-top-right-radius: 14px;" class="card-img-top imgAll" alt="...">
            <div class="card-body " >
               <p id="pBtnHoteles" class=" "  [ngStyle]="{'color':colorLeter() }" style="font-size:0.85em; margin-top: 0.4em; margin-bottom: 0.4em; text-align: center;"><strong style="font-family: 'Montserrat';">TIPO :</strong>  {{ g.titulo |titlecase}}</p>
                
               <div class=" d-grid gap-2">
                <button class="btn btn-sm rounded-pill btnCarHabitaciones fw-bold "  style="letter-spacing: 3px; text-transform: uppercase; "
                 [ngStyle]="{'color':colorUno(), 'border-color':colorUno(), 'border-width':'2px', 'border-style':'solid' } " (click)="getRooms(g)">
                 <!-- [ngStyle]="{'background-color':colorUno(), 'color':colorLeter()} " (click)="getRooms(g)"> -->
                    Ver habitaciones
                </button>
               </div>
               <div class="col-12 pt-2">
                <p lass=" " style="text-align: left; font-family: 'Helvetica';" *ngIf="!g.view">
                    {{g.desc}}
                    <i class="fas fa-eye text-size-15 ml-2" title="VER MÁS" *ngIf="g.desc" (click)="seeMoreDescription(g)"></i>
                </p>
                <p class=" " style="text-align: left; font-family: 'Helvetica';" *ngIf="g.view">
                    {{g.descripcion}}
                    <i class="fas fa-eye-slash text-size-15 ml-2" title="VER MENOS" (click)="seeMoreDescription(g)"></i>
                </p>

            </div>
            </div>
          </div>
      
    
      
      </div>
  
   </div>

 

   

    <div class="row pb-5" *ngIf="typesRooms?.length == 1">

        <div class="col-lg-4 col-md-6  p-1"  *ngFor="let g of typesRooms; let i = index">
            <div class="card card2" style="width: 100%; border-radius: 14px;">
                <img [src]="rutaUrl+ g.img+'alt=media'"ah style="border-top-left-radius: 14px; border-top-right-radius: 18px;" class="card-img-top imgAll" alt="...">
                <div class="card-body " >
                   <p id="pBtnHoteles" class=" "  [ngStyle]="{'color':colorLeter() }" style="font-size:0.85em; margin-top: 0.4em; margin-bottom: 0.4em; text-align: center;"><strong style="font-family: 'Montserrat';">TIPO :</strong>  {{ g.titulo |titlecase}}</p>
                    
                   <div class=" d-grid gap-2">
                    <button class="btn btn-sm rounded-pill btnCarHabitaciones fw-bold "  style="letter-spacing: 3px; text-transform: uppercase; "
                     [ngStyle]="{'color':colorUno(), 'border-color':colorUno(), 'border-width':'2px', 'border-style':'solid' } " (click)="getRooms(g)">
                     <!-- [ngStyle]="{'background-color':colorUno(), 'color':colorLeter()} " (click)="getRooms(g)"> -->
                        Ver habitaciones
                    </button>
                   </div>
                   <div class="col-12 pt-2">
                    <p lass=" " style="text-align: left; font-family: 'Helvetica';" *ngIf="!g.view">
                        {{g.desc}}
                        <i class="fas fa-eye text-size-15 ml-2" title="VER MÁS" *ngIf="g.desc" (click)="seeMoreDescription(g)"></i>
                    </p>
                    <p class=" " style="text-align: left; font-family: 'Helvetica';" *ngIf="g.view">
                        {{g.descripcion}}
                        <i class="fas fa-eye-slash text-size-15 ml-2" title="VER MENOS" (click)="seeMoreDescription(g)"></i>
                    </p>
    
                </div>
                </div>
              </div>
       
        
          
          </div>

       
    </div>

</section>

<!-- Modal mostrar con la habitaciones del tipo seleccionado -->
<ng-template #roomReservationModal let-modal>

    <form class="header-search-form bg-light rounded">

        <div class="row mt-3">
            <div class="col-12 text-right">
                <h3>
                    <i class="far fa-times-circle pr-3 text-danger" (click)="modal.dismiss('Cross click')"></i>
                </h3>
            </div>
        </div>

        <div class="modal-body">

            <div class="row divSelect">

                <div class="col-lg-4 text-center w-100" *ngFor="let r of rooms">
                  
                
                        <div class="card">
                            <!-- <img src="https://drive.google.com/uc?export=view&id={{r.imagenPrincipal}}" style="border-radius: 18px;" class="card-img-top imgAll p-2" /> -->
                            <img *ngIf="r.imagenPrincipal != '1p0vP1H04zultmn8C1oI6oeTqkp6IbzSK'" [src]="rutaUrl+ r.imagenPrincipal+'?alt=media'" style="border-radius: 18px;" class="card-img-top imgAll p-2" />
                            <img  *ngIf="r.imagenPrincipal == '1p0vP1H04zultmn8C1oI6oeTqkp6IbzSK'" src="../../../assets/img/generica.jpg" style="border-radius: 18px;" class="card-img-top imgAll p-2" />
                           
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-lg-8 col-md-8">
                                        <h5 class="card-title fw-bold pt-2" style="font-family: 'Montserrat; letter-spacing='0.3em; text-align:left " [ngStyle]="{'color':colorLeter() }" >{{r.type}} # {{r.nro}}</h5>
  
                                    </div>
                                    <div class="col-lg-4 col-md-4" >
                                        <button type="button" style="float: right;" (click)="modal.close(r)" class="btn rounded-circle card1"  [ngStyle]="{'background-color':colorLeter() }">
                                            
                                            <img src="../../../assets/img/icon-calendario-1.png"  style="width: 25px; height: 30px; object-fit: scale-down; "> 
                                        </button>
                                    </div>
                                </div>

                              
                                <div class="profile-description pt-2" style="text-align: left; opacity: .80;">
                                    <p class="card-text text-center" *ngIf="!r.view">
                                        {{r.desc}}
                                        <i class="fas fa-eye text-size-15 ml-2" *ngIf="r.icn_view"
                                            (click)="seeMoreDescription(r)"></i>
                                    </p>
                                    <p class="card-text text-center" *ngIf="r.view">
                                        {{r.descripcion}}
                                        <i class="fas fa-eye-slash text-size-15 ml-2" (click)="seeMoreDescription(r)"></i>
                                    </p>                                 
                            </div>
                             
                             
                            </div>
                          </div> 

                        <!-- <div class="profile-card-4 text-center">
                            <img src="https://drive.google.com/uc?export=view&id={{r.imagenPrincipal}}" class="card-img-top imgAll" >
                            <div class="profile-content">
                                  <div class="profile-description">
                                        <p class="card-text text-center" *ngIf="!r.view">
                                            {{r.desc}}
                                            <i class="fas fa-eye text-size-15 ml-2" *ngIf="r.icn_view"
                                                (click)="seeMoreDescription(r)"></i>
                                        </p>
                                        <p class="card-text text-center" *ngIf="r.view">
                                            {{r.descripcion}}
                                            <i class="fas fa-eye-slash text-size-15 ml-2" (click)="seeMoreDescription(r)"></i>
                                        </p>                                 
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="profile-overview">
                                            <p>Número</p>
                                            <h4># {{r.nro}}</h4>
                                        </div>
                                    </div>
                                    
                                    <div class="col-6">
                                        <div class="profile-overview">
                                            <p>Reservar</p>
                                            <h4>
                                                <button type="button" class="btn btn-light border" (click)="modal.close(r)">
                                                    <i class="fas fa-concierge-bell ml-2 mr-2"></i>
                                                </button>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->


                    <!-- </div> -->

                    <!-- <div class="card" style="width: 100%; border-radius: 14px;">
                        <img src="https://drive.google.com/uc?export=view&id={{r.imagenPrincipal}}" style="border-top-left-radius: 14px; border-top-right-radius: 18px;" class="card-img-top" alt="...">
                        <div class="card-body " s>
                           <p id="pBtnHoteles" class=" " style="font-size:1em; margin-top: 0.4em; margin-bottom: 0.4em; text-align: center;"><strong style="font-family: 'Montserrat';"></strong>  {{r.type}} #{{r.nro}}</p>
                            
                           <div class="col-12 pt-2">
                            <p class="card-text text-center" *ngIf="!r.view">
                                {{r.desc}}
                                <i class="fas fa-eye text-size-15 ml-2" *ngIf="r.icn_view"
                                    (click)="seeMoreDescription(r)"></i>
                            </p>
                            <p class="card-text text-center" *ngIf="r.view">
                                {{r.descripcion}}
                                <i class="fas fa-eye-slash text-size-15 ml-2" (click)="seeMoreDescription(r)"></i>
                            </p>
                        </div>
                        </div>
                      </div> -->
                    
                    <!-- <div class="">

                        <div class="img-promocion-web">
                            <img src="https://drive.google.com/uc?export=view&id={{r.imagenPrincipal}}" alt=""
                                (click)="modal.close(r)">
                               
                        </div>

                        <div class="card shadow p-2 mb-3 bg-white rounded ">
                            <div class="card-body">
                                <h5 class="card-title text-center text-titulo-tipo-habitacion">{{r.type}} #{{r.nro}}
                                </h5>
                                <hr>
                                <p class="card-text text-center" *ngIf="!r.view">
                                    {{r.desc}}
                                    <i class="fas fa-eye text-size-15 ml-2" *ngIf="r.icn_view"
                                        (click)="seeMoreDescription(r)"></i>
                                </p>
                                <p class="card-text text-center" *ngIf="r.view">
                                    {{r.descripcion}}
                                    <i class="fas fa-eye-slash text-size-15 ml-2" (click)="seeMoreDescription(r)"></i>
                                </p>
                                <hr>
                                <button type="button" class="btn btn-light border" (click)="modal.close(r)">
                                    <i class="fas fa-concierge-bell ml-2 mr-2"></i>
                                </button>
                            </div>
                        </div>

                    </div> -->

                </div>

            </div>

        </div>

        <br>
    </form>

</ng-template>

<!-- Crear la reservacion de la habitacion -->
<ng-template #createReservationModal let-modal>

    <form class="header-search-form bg-light rounded">

        <div class="row mt-2">
            <div class="col-6 text-left">
                <h3>
                    <i class="fas fa-arrow-circle-left pl-5" (click)="modal.close('return')"></i>
                </h3>
            </div>
            <div class="col-6 text-right">
                <h3>
                    <i class="far fa-times-circle pr-5 text-danger" (click)="modal.dismiss('Cross click')"></i>
                </h3>
            </div>
        </div>

        <hr style="margin: 0px; ">

        <div class="modal-body">

            <div class="row">

                <div class="col-lg-6 " >
                    <div class="row p-2">
                     
                          
                        <div class="col-lg-12"  >
                            <img class="img-room-reservation animated fadeIn imgAll "  *ngIf="roomSelected.imagen_uno"
                                [src]="rutaUrl+ roomSelected.imagenPrincipal+'?alt=media'"
                                alt="">

                                <img  class="img-room-reservation animated fadeIn imgAll " *ngIf="!roomSelected.imagen_uno"
                                src="../../../assets/img/generica.jpg "
                                alt=""
                               
                                >
                            <!-- <img class="img-room-reservation animated fadeIn imgAll " 
                                src="https://drive.google.com/uc?export=view&id={{roomSelected.imagenPrincipal}}"
                                alt=""> -->
                        </div>

                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-4 div-img-min">
                     
                                        <img class=" mt-2 img-min rounded card imgAll2" *ngIf="roomSelected.imagen_uno"
                                        [src]="rutaUrl+ roomSelected.imagen_uno+'?alt=media'"
                                        (click)="SelectImageReservation(roomSelected, roomSelected.imagen_uno)"
                                        alt="">
                                        <img class="mt-2 img-min rounded card imgAll2"*ngIf="!roomSelected.imagen_uno"
                                        src="../../../assets/img/generica.jpg"
                                        alt=""
                                       
                                        >
                                </div>

                                <div class="col-4 div-img-min">
                                    <img class="mt-2 img-min rounded card imgAll2 "  *ngIf="roomSelected.imagen_dos"
                                    [src]="rutaUrl+ roomSelected.imagen_dos+'?alt=media'"
                                    (click)="SelectImageReservation(roomSelected, roomSelected.imagen_dos)"
                                    alt="">
                                    <img class="mt-2 img-min rounded card imgAll2"*ngIf="!roomSelected.imagen_dos"
                                    src="../../../assets/img/generica.jpg "
                                    alt=""
                                   
                                    >
                                </div>

                                <div class="col-4 div-img-min">
                                    <img class="mt-2 img-min rounded card imgAll2 "  *ngIf="roomSelected.imagen_tres"
                                    [src]="rutaUrl+ roomSelected.imagen_tres+'?alt=media'"
                                    (click)="SelectImageReservation(roomSelected, roomSelected.imagen_tres)"
                                    alt="">
                                    <!-- <img class="mt-2 img-min rounded card imgAll2"  *ngIf="roomSelected.imagen_tres"
                                        src="https://drive.google.com/uc?export=view&id={{roomSelected.imagen_tres}}"
                                        alt="" (click)="SelectImageReservation(roomSelected, roomSelected.imagen_tres)"> -->
                                    <img class="mt-2 img-min rounded card imgAll2"*ngIf="!roomSelected.imagen_tres"
                                        src="../../../assets/img/generica.jpg "
                                        alt=""
                                       
                                        >
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-lg-4">
                            <div class="row">
                                <div class="col-12 div-img-min">
                                    <img class="img-min rounded" *ngIf="roomSelected.imagen_uno"
                                        src="https://drive.google.com/uc?export=view&id={{roomSelected.imagen_uno}}"
                                        alt="" (click)="SelectImageReservation(roomSelected, roomSelected.imagen_uno)">
                                    <img class="img-min rounded" *ngIf="!roomSelected.imagen_uno"
                                        src="https://drive.google.com/uc?export=view&id=1p0vP1H04zultmn8C1oI6oeTqkp6IbzSK"
                                        alt="">
                                </div>

                                <div class="col-12 div-img-min">
                                    <img class="mt-2 img-min rounded" *ngIf="roomSelected.imagen_dos"
                                        src="https://drive.google.com/uc?export=view&id={{roomSelected.imagen_dos}}"
                                        alt="" (click)="SelectImageReservation(roomSelected, roomSelected.imagen_dos)">
                                    <img class="mt-2 img-min rounded" *ngIf="!roomSelected.imagen_dos"
                                        src="https://drive.google.com/uc?export=view&id=1p0vP1H04zultmn8C1oI6oeTqkp6IbzSK"
                                        alt="">
                                </div>

                                <div class="col-12 div-img-min">
                                    <img class="mt-2 img-min rounded" *ngIf="roomSelected.imagen_tres"
                                        src="https://drive.google.com/uc?export=view&id={{roomSelected.imagen_tres}}"
                                        alt="" (click)="SelectImageReservation(roomSelected, roomSelected.imagen_tres)">
                                    <img class="mt-2 img-min rounded" *ngIf="!roomSelected.imagen_tres"
                                        src="https://drive.google.com/uc?export=view&id=1p0vP1H04zultmn8C1oI6oeTqkp6IbzSK"
                                        alt="">
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-8">
                            <img class="img-room-reservation " 
                                src="https://drive.google.com/uc?export=view&id={{roomSelected.imagenPrincipal}}"
                                alt="">
                        </div> -->
                    </div>
                    <!-- ======================= -->
                    <div class="col-lg-12  col-12 text-center w-100 pt-4 pb-4 ">

                        <div class="row text-center">
    
                            <!-- Fecha del dia actual -->
                            <div class="col-12">
                                <div class="row">
    
                                    <div class="col-2">
                                        <i class="fas fa-chevron-circle-left text-size-20 w-100"
                                            *ngIf="reservation.viewBtnPrevious"
                                            (click)="getPreviousMonth(roomSelected, 'previous')"></i>
                                    </div>
    
                                    <div class="col-8 pb-2" >
                                        <p class="text-tituto-general">
                                            <strong>{{calendar.date_current}}</strong>
                                        </p>
                                    </div>
    
                                    <div class="col-2 w-100">
                                        <i class="fas fa-chevron-circle-right text-size-20 w-100"
                                            (click)="getNextMonth(roomSelected, 'next')"></i>
                                    </div>
    
                                </div>
    
                            </div>
    
                            <!-- Calendario -->
                            <div class="ol-day col-12" style="float: left;">
                                

                                <div class="mb-2 " *ngFor="let day of week">
                                    {{day | slice:0:3}}
                                </div>
    
                                <div class="w-100 mb-2" [style.gridColumnStart]="first ? day?.indexWeek : 'auto'"
                                    *ngFor="let day of reservation.daysMont;let first = first">
    
                                    <div class="" *ngIf="!day.active">
                                        <div class="bg-light btn rounded-circle text-dark border none-cursor" style="font-size: 0.85em;"
                                            (click)="viewMessageAlert('Seleccione una fecha vigente', 'warning')">
                                            {{day?.value}}
                                        </div>
                                    </div>
    
                                    <div class="" *ngIf="day.active">
                                        <!-- <div class="bg-danger text-white text-size-22 tipo-cursor none-cursor ex2 rounded-pill"
                                            *ngIf="!day.available"
                                            (click)="viewMessageAlert('La fecha no se encuentra disponible', 'danger')">
                                            {{day?.value}}
                                            <i class="fas fa-ban" style="font-size: 8px;"></i>
                                        </div> -->
                                        <button type="button" class="btn btn-danger position-relative rounded-circle" 
                                        *ngIf="!day.available"  (click)="viewMessageAlert('La fecha no se encuentra disponible', 'danger')">
                                            {{day?.value}}
                                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                                                <i class="fas fa-ban text-white  " ></i>
                                            </span>
                                          </button>
                                        <div class=" text-white tipo-cursor spn-active" style="background-color: transparent; font-size: 0.85em;"
                                            *ngIf="day.available" (click)="clickDay(day)">
                                            <p class=" btn rounded-circle bg-warning" style="font-size: 0.85em; font-weight: bold;"  *ngIf="!day.selected"> {{day?.value}}</p>
                                            <!-- <p class="bg-success btn rounded-circle " *ngIf="day.selected">
                                                <i class="fas fa-thumbs-up text-white  " style="font-size: 10px;"></i>
                                            </p> -->

                                            <button type="button" class="btn btn-success position-relative rounded-circle" *ngIf="day.selected">
                                                {{day?.value}}
                                                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
                                                    <i class="fas fa-thumbs-up text-white  " ></i>
                                                </span>
                                              </button>
                                            
                                        </div>
                                    </div>
    
                                </div>
    
                            </div>
    
                        </div>
                    </div>

                </div>
                
                <!-- ========================================================================== -->
                <div class="col-lg-6 text-center card1 "  >
                    <h5 class="card-title text-center text-titulo-tipo-habitacion mt-3 fw-bold"  style="font-family: 'Montserrat'" [ngStyle]="{'color':colorLeter() }">
                        {{roomSelected.type}} 
                    </h5>
                    <small style="font-weight: bold; font-style: italic; font-family: 'Montserrat', sans-serif; font-size: 2.5em; color: #B9B9B9">
                        N° {{roomSelected.nro}}
                    </small>
                    <div class="divSelect">
                        <hr [ngStyle]="{'color':colorLeter() }" style="width: 50%;">
                    </div>
                    


                    <h6>
                        <span style="font-family: 'Montserrat'; font-size: 2em; font-weight: bold; "  [ngStyle]="{'color':colorLeter() }" class="mr-2 text-subrayado-gray text-secondary" *ngIf="roomSelected.offerPrice > 0">$.
                            {{roomSelected.offerPrice | number: '1.2'}}</span>
                        <span  style="font-family: 'Montserrat'; font-size: 2em; font-weight: bold;" [ngStyle]="{'color':colorLeter() }" >$. {{roomSelected.precio | number: '1.2'}}</span>
                    </h6>

                    <div class="divSelect">
                        <hr [ngStyle]="{'color':colorLeter() }" style="width: 50%;">             
                    </div>
                    <span class="p-2" style="font-family: 'Helvetica';">
                        {{roomSelected.descripcion}}
                    </span>

                    <div class="divSelect">
                        <hr [ngStyle]="{'color':colorLeter() }" style="width: 50%;">             
                    </div>
                    <div class="d-grid gap-2">
                        <span class=" card-header fw-bold" >Capacidad {{roomSelected.capacidad}}</span>
                    </div>
                    
                    <!-- <p class="mt-2">
                        <strong class="text-size-13">
                            Capacidad
                        </strong>
                        <span class="badge badge-success text-size-18 ml-1">{{roomSelected.capacidad}}</span>
                    </p> -->

                    <div class="row divSelect pt-3 pb-3">
                        <div class="col">
                            <h1  style="font-weight: bold; font-family: 'Montserrat', sans-serif; font-size: 1.25em; color: #B9B9B9">Adultos <strong> {{roomSelected.cap_adultos}}</strong></h1>
                        </div>
                        <div class="col">
                            <h1  style="font-weight: bold; font-family: 'Montserrat', sans-serif; font-size: 1.25em; color: #B9B9B9">Niños <strong> {{roomSelected.cap_ninos}}</strong></h1>
                        </div>
                    </div>

                    <div class="col-lg-12 text-center w-100 animated fadeIn" *ngIf="reservation.check.in">
                        <div class="row  ">
    
                            <!-- <div class="col-lg-12">
                                <div class="alert alert-success rounded-pill " role="alert">
                                    Crear Reserva
                                </div>
                            </div> -->
                            <div class="divSelect pb-2">
                                <span [ngStyle]="{'color':colorLeter() }"
                                 class="fw-bold" style="font-family: 'Montserrat'; font-size: 1.25em;" > CREAR RESERVA </span>
                            </div>
    
                            <div class="col-lg-12 mb-3 rounded-pill" *ngIf="configuracion.habitacion_por_persona">
                                <form class="w-100">
                                    <div class="w-100 " role="alert">
                                      <p style="font-size: 1em;"> Seleccione el número de personas </p>  
                                        <!-- <i class="fa fa-users ml-2" aria-hidden="true"></i> -->
                                    </div>
                                    <span class="text-size-13" style="position: absolute; left: 5%;">1</span>
                                    <i class="fa fa-male ml-3 text-success" aria-hidden="true" *ngFor="let p of persons.arr"></i>
                                    <span class="text-size-13" style="position: absolute; right: 5%;">{{persons.size}}</span>
                                    <input class="w-100 text-success" type="range" name="amountRange" min="1" max="{{persons.size}}" value="1" oninput="this.form.amountInput.value=this.value" (change)="selectPersonNumber($event.target.value)" />
                                    <input class="pl-2 border rounded" type="number" name="amountInput" min="1" max="{{persons.size}}" value="1" oninput="this.form.amountRange.value=this.value" (change)="selectPersonNumber($event.target.value)" />
                                </form>
                            </div>
    
                            <!-- <div class="col-lg-12">
                                <div class="alert alert-info w-100 text-size-14 mt-2" role="alert">
                                    <strong>Subtotal : $ {{reservation.amount * roomSelected.precio | number: '1.2'}} </strong>
                                </div>
                            </div> -->
                            <!-- <small class="pb-2" style="font-family: 'Montserrat', sans-serif; font-size: 1.75em; color: #B9B9B9">
                                <strong>Subtotal : $ {{reservation.amount * roomSelected.precio | number: '1.2'}} </strong>
                            </small> -->

                            <span   class="pb-2" [ngStyle]="{'color':colorLeter() }"
                                 class="fw-bold" style="font-family: 'Montserrat'; font-size: 1.25em;" > Subtotal : $ {{reservation.amount * roomSelected.precio | number: '1.2'}} </span>
    
                            
                                <div class="row">
                                    
                                      
                                    
                                    <div class="col-lg-6">
                                        <div class=" w-100">
                                            <div class="card-header text-size-13">
                                                <i class="fas fa-angle-double-down mr-2 text-size-15"></i>
                                                Check In:
                                            </div>
                                            <div class="card-body p-1">
                                                <span class="text-mayuscula">
                                                    {{reservation?.check.in}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="col-lg-6">
                                        <div class=" w-100">
                                            <div class="card-header text-size-13">
                                                Check Out:
                                                <i class="fas fa-angle-double-up ml-2 text-size-15"></i>
                                            </div>
                                            <div class="card-body p-1">
                                                <span class="text-mayuscula">
                                                    {{reservation?.check.out}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                           
    
                            <div class="divSelect">

                           
                            <div class=" col-lg-8 col-md-11 col-12 mt-3 mb-3">
    
                                <button type="button" class="btn btn-success btn-sm btn-block rounded-pill"
                                    (click)="verifyLoginClient(roomSelected, reservation, 'normal', detailReservationModal)">
                                    {{configuracion.txtBtnEnviarPedido1}}
                                </button>
    
                                <button type="button" class="btn btn-dark btn-sm btn-block rounded-pill"
                                    (click)="verifyLoginClient(roomSelected, reservation, 'transfer', detailReservationModal)"
                                    *ngIf="information.numeroCuenta">
                                    <i class="bi bi-bank"></i>
                                    Transferencia Bancaria
                                </button>
    
                                <button type="button" class="btn btn-warning btn-sm btn-block rounded-pill"
                                    (click)="verifyLoginClient(roomSelected, reservation, 'paypal', detailReservationModal)"
                                    *ngIf="paymentButtons.payPal.rta">
                                    <i class="fab fa-paypal text-size-18"></i>
                                    PayPal
                                </button>
    
                            </div>
                        </div>
    
                        </div>
                    </div>

                    <!-- <div class="row ml-1 mr-1">
                        <div class="col-6">
                            <div class="card w-100">
                                <div class="card-header text-size-13">
                                    <i class="fas fa-male mr-2 text-size-15"></i>
                                    Adultos
                                </div>
                                <div class="card-body">
                                    {{roomSelected.cap_adultos}}
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="card w-100">
                                <div class="card-header text-size-13">
                                    Niños
                                    <i class="fas fa-child ml-2 text-size-15"></i>
                                </div>
                                <div class="card-body">
                                    {{roomSelected.cap_ninos}}
                                </div>
                            </div>
                        </div>
                     
                    </div> -->
                </div>

            </div>

            <!-- <hr> -->

            <!-- <div class="row">

                <div class="col-lg-8 text-center w-100">

                    <div class="row text-center">

                     
                        <div class="col-12">
                            <div class="row">

                                <div class="col-2">
                                    <i class="fas fa-chevron-circle-left text-size-20 w-100"
                                        *ngIf="reservation.viewBtnPrevious"
                                        (click)="getPreviousMonth(roomSelected, 'previous')"></i>
                                </div>

                                <div class="col-8">
                                    <p class="text-tituto-general">
                                        <strong>{{calendar.date_current}}</strong>
                                    </p>
                                </div>

                                <div class="col-2 w-100">
                                    <i class="fas fa-chevron-circle-right text-size-20 w-100"
                                        (click)="getNextMonth(roomSelected, 'next')"></i>
                                </div>

                            </div>

                        </div>

                     
                        <div class="ol-day col-12" style="float: left;">

                            <div class="mb-2 mayuscula text-size-10" *ngFor="let day of week">
                                {{day | slice:0:3}}
                            </div>

                            <div class="w-100 mb-2" [style.gridColumnStart]="first ? day?.indexWeek : 'auto'"
                                *ngFor="let day of reservation.daysMont;let first = first">

                                <div class="mr-3 ml-3" *ngIf="!day.active">
                                    <div class="bg-light text-dark border text-size-22 none-cursor"
                                        (click)="viewMessageAlert('Seleccione una fecha vigente', 'warning')">
                                        {{day?.value}}
                                    </div>
                                </div>

                                <div class="mr-3 ml-3" *ngIf="day.active">
                                    <div class="bg-danger text-white text-size-22 tipo-cursor none-cursor ex2"
                                        *ngIf="!day.available"
                                        (click)="viewMessageAlert('La fecha no se encuentra disponible', 'danger')">
                                        {{day?.value}}
                                        <i class="fas fa-ban" style="font-size: 8px;"></i>
                                    </div>
                                    <div class="bg-dark text-white text-size-22 tipo-cursor spn-active"
                                        *ngIf="day.available" (click)="clickDay(day)">
                                        <p class="" *ngIf="!day.selected"> {{day?.value}}</p>
                                        <p class="bg-success" *ngIf="day.selected">{{day?.value}}
                                            <i class="fas fa-thumbs-up" style="font-size: 8px;"></i>
                                        </p>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>

                <div class="col-lg-4 text-center w-100" *ngIf="reservation.check.in">
                    <div class="row">

                        <div class="col-lg-12">
                            <div class="alert alert-success" role="alert">
                                Crear Reserva
                            </div>
                        </div>

                        <div class="col-lg-12 mb-3" *ngIf="configuracion.habitacion_por_persona">
                            <form class="w-100">
                                <div class="alert alert-warning w-100 text-size-13" role="alert">
                                    Seleccione el número de personas
                                    <i class="fa fa-users ml-2" aria-hidden="true"></i>
                                </div>
                                <span class="text-size-13" style="position: absolute; left: 5%;">1</span>
                                <i class="fa fa-male ml-3 text-success" aria-hidden="true" *ngFor="let p of persons.arr"></i>
                                <span class="text-size-13" style="position: absolute; right: 5%;">{{persons.size}}</span>
                                <input class="w-100 text-success" type="range" name="amountRange" min="1" max="{{persons.size}}" value="1" oninput="this.form.amountInput.value=this.value" (change)="selectPersonNumber($event.target.value)" />
                                <input class="pl-2 border rounded" type="number" name="amountInput" min="1" max="{{persons.size}}" value="1" oninput="this.form.amountRange.value=this.value" (change)="selectPersonNumber($event.target.value)" />
                            </form>
                        </div>

                        <div class="col-lg-12">
                            <div class="alert alert-info w-100 text-size-14 mt-2" role="alert">
                                <strong>Subtotal : $ {{reservation.amount * roomSelected.precio | number: '1.2'}} </strong>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="row">
                                
                                <div class="col-lg-6">
                                    <div class="card w-100">
                                        <div class="card-header text-size-13">
                                            <i class="fas fa-angle-double-down mr-2 text-size-15"></i>
                                            Check In:
                                        </div>
                                        <div class="card-body p-1">
                                            <span class="text-mayuscula">
                                                {{reservation?.check.in}}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="card w-100">
                                        <div class="card-header text-size-13">
                                            Check Out:
                                            <i class="fas fa-angle-double-up ml-2 text-size-15"></i>
                                        </div>
                                        <div class="card-body p-1">
                                            <span class="text-mayuscula">
                                                {{reservation?.check.out}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 mt-3">

                            <button type="button" class="btn btn-success btn-sm btn-block"
                                (click)="verifyLoginClient(roomSelected, reservation, 'normal', detailReservationModal)">
                                {{configuracion.txtBtnEnviarPedido1}}
                            </button>

                            <button type="button" class="btn btn-dark btn-sm btn-block"
                                (click)="verifyLoginClient(roomSelected, reservation, 'transfer', detailReservationModal)"
                                *ngIf="information.numeroCuenta">
                                Transferencia Bancaria
                            </button>

                            <button type="button" class="btn btn-warning btn-sm btn-block"
                                (click)="verifyLoginClient(roomSelected, reservation, 'paypal', detailReservationModal)"
                                *ngIf="paymentButtons.payPal.rta">
                                <i class="fab fa-paypal text-size-18"></i>
                                PayPal
                            </button>

                        </div>

                    </div>
                </div>

            </div> -->

        </div>

        <br>

    </form>

</ng-template>

<!-- Mostar datos de costos y especificacion de reserva -->
<ng-template #detailReservationModal let-modal>

    <div class="card row text-minuscula">

        <div class="col-lg-12 text-center mt-3">

            <div class="row">
                <div class="col-10 text-size-16 text-secondary">
                    <h6>
                        <i class="fas fa-clipboard-list mr-3 text-success"></i>
                        Detalle Reserva
                    </h6>
                </div>
                <div class="col-2"><i class="far fa-times-circle text-danger"
                        (click)="modal.dismiss('Cross click')"></i></div>
            </div>

        </div>

        <hr *ngIf="transferBank.view">

        <div class="col-lg-12 text-center text-size-13 alert alert-info" role="alert" *ngIf="transferBank.view">
            <span><strong>{{transferBank.bank}}</strong></span>
            <br>
            <span><strong>{{transferBank.name}}</strong></span>
            <br>
            <span><strong>{{transferBank.number}}</strong></span>
        </div>

        <hr>

        <div class="col-lg-12 text-center">

            <div class="form-group text-center" style="width: 100%;">

                <div class="text-center text-size-14">
                    <span class="text-normal mb-2"><strong> Cliente :</strong> {{resultReserv.cliente}}</span>
                </div>

                <br>

                <!-- Numero de dias -->
                <table class="table" *ngIf="resultReserv.status">
                    <thead class="thead-light">
                        <tr class="text-size-12">
                            <th scope="col">Nro. Días</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-size-16">
                            <td>{{resultReserv.amount}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- Habitacion, check-in, check-out -->
                <table class="table" *ngIf="resultReserv.status">
                    <thead class="thead-light">
                        <tr class="text-size-12">
                            <th scope="col">Hab.</th>
                            <th scope="col">Checkin</th>
                            <th scope="col">Checkout</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-size-12">
                            <td>{{resultReserv.habitacion}}</td>
                            <td>{{resultReserv.checkin}}</td>
                            <td>{{resultReserv.checkout}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- Numero de personas -->
                <table class="table" *ngIf="resultReserv.status && resultReserv.numeroPersonas > 0">
                    <thead class="thead-light">
                        <tr class="text-size-12">
                            <th scope="col">Nro. Personas</th>
                            <th scope="col">Precio x Persona</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-size-12">
                            <td>{{resultReserv.numeroPersonas}}</td>
                            <td>{{resultReserv.precioPorPersona}}</td>
                        </tr>
                    </tbody>
                </table>

                <hr>

                <div class="text-size-14 text-center">
                    <span class="text-normal mb-2 text-right">Subtotal : {{resultReserv.subtot | number: '1.2'}}</span>
                    <br>
                    <span class="text-normal mb-2 text-right">Descuento : {{resultReserv.descount | number:
                        '1.2'}}</span>
                    <br>
                    <span class="text-normal mb-2 text-right" *ngIf="resultReserv.typeTransaction == 1"> Recargo T.
                        Crédito : {{resultReserv.surchargeTc | number: '1.2'}}</span>
                </div>

                <br>

                <h6>Total: <span class="text-normal"> {{resultReserv.total | number: '1.2'}} </span></h6>

            </div>

        </div>

        <hr>

        <div class="col-lg-12 text-center">
            <div class="form-group text-center">

                <button type="button" class="btn btn-outline-success text-center" (click)="modal.close('ok')">
                    <i class="far fa-check-circle"></i>
                </button>

                <button type="button" class="btn btn-outline-danger text-center ml-3" (click)="modal.dismiss('cancel')">
                    <i class="far fa-times-circle"></i>
                </button>
            </div>
        </div>

        <br>

    </div>

</ng-template>

<!-- Mostrar resultado de crear la reserva -->
<ng-template #resultReservationModal let-modal>

    <div class="card row text-minuscula">

        <div class="col-lg-12 text-center mt-3">

            <div class="row">
                <div class="col-10 text-size-16 text-secondary">
                    <h6>
                        <i class="fas fa-concierge-bell mr-2 text-warning"></i>
                        Reservación
                    </h6>
                </div>
                <div class="col-2"><i class="far fa-times-circle text-danger"
                        (click)="modal.dismiss('Cross click')"></i></div>
            </div>

        </div>

        <hr>

        <div class="col-lg-12 text-center" id="print-section">

            <div class="form-group text-center" style="width: 100%;">
                <h1 *ngIf="resultReserv.status">
                    <i class="fas fa-clipboard-check text-success"></i>
                </h1>
                <h1 *ngIf="!resultReserv.status">
                    <i class="fas fa-times-circle text-danger"></i>
                </h1>
                <p>
                    <label class="text-size-14 mb-3">{{resultReserv.msg}}</label>
                    <label class="text-size-14 mb-2"><strong> Cliente :</strong> {{resultReserv.cliente}}</label>
                </p>

                <!-- <table class="table" *ngIf="resultReserv.status">
                    <thead class="thead-light">
                        <tr class="text-size-12">
                            <th scope="col">Res.</th>
                            <th scope="col">Hab.</th>
                            <th scope="col">Checkin</th>
                            <th scope="col">Checkout</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-size-12">
                            <th>{{resultReserv.reserva}}</th>
                            <td>{{resultReserv.habitacion}}</td>
                            <td>{{resultReserv.checkin}}</td>
                            <td>{{resultReserv.checkout}}</td>
                        </tr>

                </table> -->

                <!-- Numero de dias -->
                <table class="table" *ngIf="resultReserv.status">
                    <thead class="thead-light">
                        <tr class="text-size-12">
                            <th scope="col">Nro. Días</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-size-16">
                            <td>{{resultReserv.amount}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- Habitacion, check-in, check-out -->
                <table class="table" *ngIf="resultReserv.status">
                    <thead class="thead-light">
                        <tr class="text-size-12">
                            <th scope="col">Hab.</th>
                            <th scope="col">Checkin</th>
                            <th scope="col">Checkout</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-size-12">
                            <td>{{resultReserv.habitacion}}</td>
                            <td>{{resultReserv.checkin}}</td>
                            <td>{{resultReserv.checkout}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- Numero de personas -->
                <table class="table" *ngIf="resultReserv.status && resultReserv.numeroPersonas > 0">
                    <thead class="thead-light">
                        <tr class="text-size-12">
                            <th scope="col">Nro. Personas</th>
                            <th scope="col">Precio x Persona</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-size-12">
                            <td>{{resultReserv.numeroPersonas}}</td>
                            <td>{{resultReserv.precioPorPersona}}</td>
                        </tr>
                    </tbody>
                </table>


                <div class="text-size-14 text-center">
                    <span class="text-normal mb-2 text-right">Subtotal : {{resultReserv.subtot | number: '1.2'}}</span>
                    <br>
                    <span class="text-normal mb-2 text-right">Descuento : {{resultReserv.descount | number:
                        '1.2'}}</span>
                    <br>
                    <span class="text-normal mb-2 text-right" *ngIf="resultReserv.typeTransaction == 1"> Recargo T.
                        Crédito :
                        {{resultReserv.surchargeTc | number: '1.2'}}</span>
                </div>

                <br>

                <h6>Total: <span class="text-normal"> {{resultReserv.total | number: '1.2'}} </span></h6>

            </div>

        </div>

        <hr>

        <div class="col-lg-12 text-center">
            <div class="form-group text-center">
                <button type="button" class="btn btn-outline-dark text-center" (click)="modal.close('ok')">
                    <i class="far fa-check-circle text-success"></i>
                </button>
                <!-- <button type="button" class="btn btn-outline-dark text-center ml-4" *ngIf="resultReserv.status" (click)="modal.close('download')"
                    printSectionId="print-section" ngxPrint>
                    <i class="fas fa-download text-warning"></i>
                </button> -->
            </div>
        </div>

        <br>

    </div>

</ng-template>

<ng-template #paypalModal let-modal>
    <div class="card modal-pago-paypal">
        <div class="row p-3 tipo-letra">
            <div class="col-2 float-left">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>

        <div class="modal-body tipo-letra">
            <ngx-paypal [config]="payPalConfig"></ngx-paypal>
        </div>
    </div>
</ng-template>

<app-footer></app-footer>