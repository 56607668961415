import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


import { ServicesService } from '../../../shared/services/services.service';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  public configuracion: any = {};
  public user: any = {};
  public reservations: any = [];

  public menu = {
    datosGenerales: true,
    reservas: false,
  }
  public loading = false;

  public closeResult: string;


  constructor(
    private webService: ServicesService,
    private toaster: ToastrService,
    private modalCtrl: NgbModal
  ) {
    // console.log("Usuario");
  }

  async ngOnInit() {
    this.loading = true;
    await this.getConfiguration();
    await this.verifyLoginClient();
    this.loading = false;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  async getConfiguration() {
    await this.webService.getConfiguracion().then(async (data: any) => {
      if (!data.error) {
        if (data[0]) {
          this.configuracion = data[0];
          // console.log("Configuracion", this.configuracion);
        } else {
          console.log("No se ha encontrado configuracion");
        }
      } else {
        this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  async selectMenu(type) {
    this.menu = {
      datosGenerales: false,
      reservas: false
    }

    if (type == "datosGenerales") {
      this.menu.datosGenerales = true;
    }
    if (type == "reservas") {
      this.menu.reservas = true;
      this.loading = true;
      await this.getReservationsUser();
      this.loading = false;

    }

  }

  async verifyLoginClient() {
    await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then(async (login: any) => {
      if (login.rta == true) {
        await this.webService.getUrlEmpresa().then(async (url) => {
          await this.webService.getUserCedula(url, login.data.PersonaComercio_cedulaRuc).then((resuser: any) => {
            this.user = resuser[0];
            // console.log("userrr", this.user);
          });
        });
      }
    });
  }

  async getReservationsUser() {
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.getReservationsCedula(url, this.user.PersonaComercio_cedulaRuc).then(async (resreserv: any) => {
        if (resreserv.rta == true) {
          for (let r of resreserv.data) {
            if (r.estado == 0 && (r.pin != 0)) {
              r.status = 'Facturada';
              r.color = 'text-success';
            }
            if (r.estado == 1 && (r.pin != 0)) {
              r.status = 'En uso'
              r.color = 'text-warning';
            }
            if (r.estado == 2 && (r.pin == 0)) {
              r.status = 'Reservada'
              r.color = 'text-secondary';
            }
          }
          await this.webService.orderObjectsAsc(resreserv.data, 'reservas').then((resorder) => {
            this.reservations = resorder;
          });
          // console.log("reservas", this.reservations);
        } else {
          this.toaster.warning('No se han encontrado reservas', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    });
  }

  async goHome() {
    await this.webService.goHome();
  }

  async signOff() {
    await this.webService.signOuth(this.configuracion.loginStorage).then(async (resClose: any) => {
      this.goHome();
    });
  }

  async ModaldeleteFile(deleteFileModal, data, type) {
    this.modalCtrl.open(deleteFileModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
      if (type == "cancelarReserva") {
        this.loading = true;
        await this.webService.getUrlEmpresa().then(async (url) => {
          await this.webService.cancelReservation(url, data.id_res).then(async (rescancel: any) => {
            // console.log("rescancel", rescancel);
            if (rescancel.rta == 1) {
              this.toaster.success('La reserva ha sido cancelada', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
              await this.getReservationsUser();
            } else {
              this.toaster.error('Ha ocurrido un error, inetente nuevamente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
            }
          });
        });
        this.loading = false;
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


}
