import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


import { ServicesService } from '../../shared/services/services.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  public loadingAll = false;
  public configuracion: any = {};
  public information: any = {};
  public aboutUs = {
    title: '',
    text: '',
    image: ''
  }
  public videoYoutube;
  public videoY:any;
  public imagenBanner = [];
  public imagenBanner1 = [];
  companyNane:any;
  colorPrimario:any;
  colorLetra:any;
  banderaCardVideo = false;
  urlBase = environment.firebaseUrl;
  constructor(
    private webService: ServicesService,
    private toaster: ToastrService
  ) { }

  async ngOnInit() {
    await this.getConfiguracion();
    await this.getInformation();
    await this.getBanner();
  }

  async getConfiguracion() {
    this.webService.getConfiguracion().then(async (data: any) => {
      if (!data.error) {
        this.configuracion = data[0];
        this.colorPrimario = this.configuracion.colorPrincipal;
        this.colorLetra = this.configuracion.colorLetra;
        // console.log(this.configuracion);
        

        // let dh = document.getElementById('div-historia');
        // dh.style.border = '10px solid' + this.configuracion.colorPrincipal;
        // dh.style.color = this.configuracion.colorLetra;
        // document.getElementById('p-historia').style.color = this.configuracion.colorLetra;

        // let dm = document.getElementById('div-mision');
        // dm.style.border = '10px solid' + this.configuracion.colorPrincipal;
        // dm.style.color = this.configuracion.colorLetra;
        // document.getElementById('p-mision').style.color = this.configuracion.colorLetra;

        // let dv = document.getElementById('div-vision');
        // dv.style.border = '10px solid' + this.configuracion.colorPrincipal;
        // dv.style.color = this.configuracion.colorLetra;
        // document.getElementById('p-vision').style.color = this.configuracion.colorLetra;

        // let dvl = document.getElementById('div-valores');
        // dvl.style.border = '10px solid' + this.configuracion.colorPrincipal;
        // dvl.style.color = this.configuracion.colorLetra;
        // document.getElementById('p-valores').style.color = this.configuracion.colorLetra;
        
        // document.getElementById('hr-down').style.borderColor = this.configuracion.colorLetra;

      } else {
        this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  colorUno(){
    // console.log('P',this.colorPrimario );
    return this.colorPrimario 
    
  }
  colorLeter(){
    return this.colorLetra;
  }

  async getBanner() {
    this.webService.getImageBanner().then((resbanner: any) => {
      // console.log("BAnnerr", resbanner);
      this.imagenBanner = resbanner[0].url;
      if(resbanner.length>1){
        this.imagenBanner1 = resbanner[1].url;
      }
    

      // console.log(this.imagenBanner);
      
    });
  }


  async getInformation() {
    await this.webService.getInformacion().then(async (resinfo: any) => {
      if (!resinfo.error) {
        if (resinfo[0]) {
          this.information = resinfo[0];
          
          this.companyNane =  this.information.nombre;
          // console.log(this.companyNane);
          
          this.selectAboutUs('historia');
          // console.log("Informacion", this.information);
        } else {
          console.log("No se ha encontrado information");
        }
      } else {
        this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
      if (resinfo[0].video) {
        this.videoYoutube = this.webService.getIdVideoYoutube(resinfo[0].video);
      } else {
        this.videoYoutube = '';
      }

      let video = resinfo[0].video;
      if(video==''){
        this.banderaCardVideo = false;
      }else{
        this.banderaCardVideo = true;
      }
      // console.log('video', video);
      let urlV =  video.slice(32)
      let url = 'https://www.youtube.com/embed/'+urlV;
      this.videoY = url;
    });
  }

  async selectAboutUs(about) {

      if (about == 'historia') {
        this.aboutUs = {
          title: 'HISTORIA',
          text: this.information.historia,
          image: this.information.imagenHistoria
        }
      }

      if (about == 'mision') {
        this.aboutUs = {
          title: 'MISION',
          text: this.information.mision,
          image: this.information.imagenMision
        }
      }

      if (about == 'vision') {
        this.aboutUs = {
          title: 'VISION',
          text: this.information.vision,
          image: this.information.imagenVision
        }
      }

      if (about == 'valores') {
        this.aboutUs = {
          title: 'VALORES',
          text: this.information.valores,
          image: this.information.imagenValores
        }
      }

  }

}
