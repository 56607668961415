import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';

import { ServicesService } from '../services/services.service';
import { LoginUserComponent } from '../../pages/login/login-user/login-user.component';
import { LoginAdminComponent } from '../../pages/login/login-admin/login-admin.component';
import { AppContext } from '../../../infrastructure/app.context';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @ViewChild("loginUserModal", {}) loginUserModal: TemplateRef<any>;
  @ViewChild("registerUserModal", {}) registerUserModal: TemplateRef<any>;

  public closeResult: string;

  public loadingAll = false;
  public loading = false;
  public configuracion: any;
  public companyNane;
  public clientLogin = {
    name: '',
    imagen: '',
    login: false,
    rol: ''
  }

  public whatsApp = {
    texto: '',
    numero: ''
  };

  colorPrimario:any;
  colorLetra:any;
  urlRuta = environment.firebaseUrl;

  constructor(
    private modalCtrl: NgbModal,
    private toaster: ToastrService,
    private webService: ServicesService,
    public router: Router,
    private localStorage: LocalStorage,
    public meta: Meta,
    private appContext: AppContext
  ) {
    // if (!this.appContext.Session.Gapi.isSignedIn) {
    //   console.log("Not Google");
    // } else {
    //   console.log("Yes Google");

    // }
  }

  async ngOnInit() {
    await this.getConfiguration();
    await this.getInformacion();
    await this.webService.observableLoginUser().subscribe((reslogin: any) => {
      this.clientLogin = {
        name: reslogin.name,
        imagen: reslogin.imagen,
        login: reslogin.login,
        rol: reslogin.rol
      }
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === ModalDismissReasons) {
      return `with: ${reason}`;
    }
  }

  async getConfiguration() {
    await this.webService.getConfiguracion().then(async (data: any) => {
      if (!data.error) {
        if (data[0]) {
          this.configuracion = data[0];
          await this.isAutenticatedClient(this.configuracion);
          this.colorPrimario = this.configuracion.colorPrincipal;
          this.colorLetra = this.configuracion.colorLetra;

          // Menu
          // let nm = document.getElementById('nav-menu');
          // nm.style.background = this.configuracion.colorPrincipal;
          // nm.style.color = this.configuracion.colorLetra;

          this.meta.updateTag({ property: "og:url", content: this.configuracion.dominioPagina });
          this.meta.updateTag({ property: "og:image", content: "https://drive.google.com/open?id=15Rqx2hoxpiQoR7bN5eioTO2WIjJaFv8r" });
        } else {
          console.log("No se ha encontrado configuracion");
        }
      } else {
        this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  colorUno(){
    // console.log('P',this.colorPrimario );
    return this.colorPrimario 
    
  }
  colorLeter(){
    return this.colorLetra;
  }

  async getInformacion() {
    this.webService.getInformacion().then((resinfo: any) => {
      this.companyNane = resinfo[0].nombre;
    });
  }

  async loginUser() {
    this.modalCtrl.open(LoginUserComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log(reason);
    });
  }

  async loginAdmin() {
    this.modalCtrl.open(LoginAdminComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log(reason);
    });
  }

  async isAutenticatedClient(configuracion) {
    await this.webService.isAuthenticatedClient(configuracion.loginStorage).then((reslogin: any) => {
      // console.log("asdasd", reslogin);
      if (reslogin.rta == true) {
        this.clientLogin = {
          name: reslogin.data.nameUser,
          imagen: reslogin.data.imagen,
          login: true,
          rol: reslogin.data.rol
        }
      } else {
        this.clientLogin = {
          name: '',
          imagen: '',
          login: false,
          rol: ''
        }
      }
      // console.log(this.clientLogin);
    });
  }

  async goClientProfile() {
    await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then(async (reslogin: any) => {
      if (reslogin.rta == true) {
        if (reslogin.data.rol == 'Client') {
          // this.router.navigate(['user']);
          this.webService.goUserProfile();
        }
        if (reslogin.data.rol == 'Administrator') {
          // this.router.navigate(['admin']);
          this.webService.goAdminProfile();
        }
      }
    });
  }

  async signOff() {
    this.webService.signOuth(this.configuracion.loginStorage).then((resClose: any) => { });
  }

  async goSectionRoom() {
    if (this.router.url == '/home') {
      let porcent = (screen.height * 60) / 100;
      window.scrollTo(0, porcent)
    } else {
      this.webService.goHome();
    }
  }

  async goHome() {
    this.webService.goHome();
  }

  async goToAboutUs() {
    this.webService.goToAboutUs();
  }

  async goPromotions() {
    this.loading = true;
    await this.webService.getPromotion().then((respromo: any) => {
      this.loading = false;
      if (respromo.rta == true) {
        this.webService.goPromotions();
      } else {
        this.toaster.warning('No existen ofertas actualmente', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  async contactanosWhatsApp(whatsapp) {
    this.loading = true;
    await this.webService.getInformacion().then((resinfo: any) => {
      this.loading = false;
      this.whatsApp = {
        numero: resinfo[0].whatsapp,
        texto: ''
      }
    });
    if (this.whatsApp.numero) {
      this.modalCtrl.open(whatsapp, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm', windowClass: 'modal-whatsapp' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
        if (this.whatsApp.texto) {
          window.open("https://wa.me/" + this.whatsApp.numero + "?text=" + this.whatsApp.texto + ".", "_blank");
        } else {
          this.toaster.warning('Ingrese algún texto', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else {
      this.toaster.error('El WhatsApp de la tienda no se encuantra agregado, comuniquese con el Administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }
  }
  
}
