import { Injectable } from "@angular/core";
import { AppRepository } from "../repositories/app.repository";

@Injectable()
export class UserSession {
    constructor(
        private appRepository: AppRepository
    ) {

    }



    
}