import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

import { ServicesService } from '../services/services.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  public informacion: any;
  public configuracion: any;
  public footerText;
  videoYoutube: SafeResourceUrl;
  public loadingAll = false;

  colorPrimario:any;
  colorLetra:any;
  urlBase = environment.firebaseUrl;

  constructor(
    private webService: ServicesService,
    public router: Router,
    private _sanitizer: DomSanitizer,
    private toaster: ToastrService,
  ) { }

  async ngOnInit() {
    await this.getInformacion();
    await this.getConfiguracion();
    await this.createFooterText();
  }

  async getInformacion() {
    this.webService.getInformacion().then(async (dataInfo: any) => {
      // console.log("Info footer", dataInfo[0]);
      this.informacion = dataInfo[0];
      if (dataInfo[0].video) {
        await this.pocesarUrlVideo(dataInfo[0].video).then((resVid) => {
          this.videoYoutube = resVid;
        });
      } else {
        this.videoYoutube = '';
      }
    });
  }

  async getConfiguracion() {
    await this.webService.getConfiguracion().then(async (dataConf: any) => {
      // console.log("Conf footer", dataConf[0]);
      this.configuracion = dataConf[0];
      this.colorPrimario = this.configuracion.colorPrincipal;
      this.colorLetra = this.configuracion.colorLetra;

      // let fs = document.getElementById('footer-section');
      // fs.style.background = dataConf[0].colorPrincipal;
      // fs.style.color = dataConf[0].colorLetra;

      // let dv = document.getElementById('div-contactos');
      // dv.style.background = dataConf[0].colorPrincipal;
      // dv.style.color = dataConf[0].colorLetra;

      // let de = document.getElementById('div-empresa');
      // de.style.background = dataConf[0].colorPrincipal;
      // de.style.color = dataConf[0].colorLetra;

      // let dr = document.getElementById('div-redes');
      // dr.style.background = dataConf[0].colorPrincipal;
      // dr.style.color = dataConf[0].colorLetra;




      // let tr = document.getElementById('tit-redes');
      // tr.style.color = dataConf[0].colorLetra;
      // // tr.style.color = dataConf[0].colorPrincipal;

      // let te = document.getElementById('tit-empresa');
      // te.style.color = dataConf[0].colorLetra;
      // te.style.color = dataConf[0].colorPrincipal;

      // Agregar color establecio a los iconos de informacion
      // let id = document.getElementById('icn-direccion');
      // id.style.color = dataConf[0].colorLetra;
      // id.style.color = dataConf[0].colorPrincipal;

      // let it = document.getElementById('icn-telefono');
      // it.style.color = dataConf[0].colorPrincipal;

      // let ie = document.getElementById('icn-email');
      // ie.style.color = dataConf[0].colorPrincipal;

      // let iw = document.getElementById('icn-whats');
      // iw.style.color = dataConf[0].colorPrincipal;

    });
  }

  colorUno(){
    // console.log('P',this.colorPrimario );
    return this.colorPrimario 
    
  }
  colorLeter(){
    return this.colorLetra;
  }

  async pocesarUrlVideo(url) {
    let s = '=';
    let a = url.split(s, 2);
    let urlView = this._sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + a[1]);
    return urlView;
  }

  async createFooterText() {
    await this.webService.getCurrentDate().then((resDate: any) => {
      this.footerText = '© ' + resDate.year + ', Developed by Punto Pymes | Copyright | All rights reserved.';
    });
  }

  async goToSocialNetwork(redSocial) {
    window.open(redSocial, "_blank");
  }

  async goToAboutUs(type) {
    this.webService.goToAboutUs();
  }

  async goLocation() {
    this.loadingAll = true;
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.getDataCompany(url).then((res: any) => {
        // console.log("Company", res);
        this.loadingAll = false;
        if (res.latitude && res.longitude) {
          window.open('https://maps.google.com/?q=' + res.latitude + ',' + res.longitude, "_blank");
        } else {
          this.toaster.warning('No se ha encontrado la ubicación, comuníquese con el administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
    });
  }

}
