<ngx-loading [show]="loadingAll" [config]="{ backdropBorderRadius: '3px',  fullScreenBackdrop: true }" [template]="">
</ngx-loading>

<!-- Logeo del Usuario -->
<div class="container">

    <form class="header-search-form text-minuscula">

        <div class="col-lg-12 text-center mt-3">
            <div class="row">
                <div class="col-10 text-size-16 text-secondary">
                    <h6>
                        <i class="fas fa-unlock-alt text-dark mr-2"></i>
                        Iniciar Sesión
                    </h6>
                </div>
                <div class="col-2"><i class="far fa-times-circle text-danger" (click)="dismissModal('Cross click')"></i></div>
            </div>
        </div>

        <hr>

        <div class="modal-body">

            <div class="form-group">
                <p>
                    <i class="fas fa-id-card-alt mr-2 text-dark"></i>
                    <label class="text-size-14 mb-3">Cédula / Ruc *</label>
                    <input type="text" class="form-control form-control-sm" name="nombres" [(ngModel)]="login.usuario">
                </p>
            </div>

            <div class="form-group div-pass">
                <i class="fas fa-lock mr-2 text-dark"></i>
                <label class="text-size-14 mb-3">
                    Contraseña *
                    <i class="fas fa-info-circle ml-4 text-size-16" (click)="showInfoPass()"></i>
                </label>
                <div class="div-input right-addon">
                    <i class="icn-input fas fa-eye-slash text-size-14" role="button" matSuffix (click)="login.viewPass = !login.viewPass" *ngIf="!login.viewPass"></i>
                    <i class="icn-input fas fa-eye text-size-14" role="button" matSuffix (click)="login.viewPass = !login.viewPass" *ngIf="login.viewPass"></i>
                    <input type="password" class="form-control form-control-sm" placeholder="Cédula / Ruc" name="password" matInput [type]="login.viewPass ? 'password': 'text'" [(ngModel)]="login.clave">
                </div>
            </div>

            <br>

            <div class="text-center">
                <button type="button" id="btn-login" class="btn text-size-14 btn-sm btn-block border"
                    (click)="dismissModal('Login')" type="submit">
                    Iniciar Sesion
                </button>
            </div>

            <hr>

            <div class="form-group text-center">
                <p>
                    <span class="text-size-14"><strong> ¿ No tienes cuenta ? </strong></span>
                </p>

                <button type="button" id="btn-register" class="btn text-size-14 btn-sm btn-block mt-3 border"
                    (click)="dismissModal('Registrar')">
                    Registrarse
                </button>

            </div>

            <hr>

            <div class="form-group text-center text-size-13">
                <strong>
                    <label class="form-check-label text-azul txt-olvido-pass" for="exampleCheck1"
                        (click)="dismissModal('Recover')">
                        ¿Ha olvidado su contraseña?
                    </label>
                </strong>
            </div>


        </div>

    </form>

</div>

<!-- Registrar Usuario en nuestra BD -->
<ng-template #modalRegisterClient let-modal>
    <form class="header-search-form text-minuscula">
        <div class="col-lg-12 text-center mt-3">

            <div class="row">
            
                <div class="col-10 text-center text-size-16 text-secondary">
                    <h6>
                        <i class="fas fa-check-circle mr-2 text-success"></i>
                        Registrarse
                    </h6>
                </div>

                <div class="col-2"><i class="far fa-times-circle text-danger" (click)="modal.dismiss('Cross click')"></i></div>
            </div>

        </div>
        <hr>
        <div class="col-lg-12">


            <div class="form-group">
                <p>
                    <i class="fas fa-id-card-alt mr-2 text-dark"></i>
                    <label class="text-size-14 mb-3">Cedula / Ruc *</label>
                    <input type="text" class="form-control form-control-sm" name="cedula"
                        [(ngModel)]="newClient.cedula">
                </p>
            </div>

            <div class="form-group">
                <p>
                    <i class="fas fa-user-tie mr-2 text-dark"></i>
                    <label class="text-size-14 mb-3">Nombres *</label>
                    <input type="text" class="form-control form-control-sm" name="nombres"
                        [(ngModel)]="newClient.nombres">
                </p>
            </div>

            <div class="form-group">
                <p>
                    <i class="fas fa-file-signature mr-2 text-dark"></i>
                    <label class="text-size-14 mb-3">Apellidos *</label>
                    <input type="text" class="form-control form-control-sm" name="apellidos"
                        [(ngModel)]="newClient.apellidos">
                </p>
            </div>

            <div class="form-group">
                <p>
                    <i class="fas fa-envelope mr-2 text-dark"></i>
                    <label class="text-size-14 mb-3">Correo Electrónico *</label>
                    <input type="text" class="form-control form-control-sm" name="email" [(ngModel)]="newClient.email">
                </p>
            </div>

            <hr>

            <button type="button" class="btn btn-outline-dark text-size-14 btn-sm btn-block"
                (click)="modal.close(newClient)" type="submit">
                Registrarse
            </button>

        </div>

        <br>
    </form>
</ng-template>

<!-- Recuperar Contraseña -->
<ng-template #modalRecoverPassword let-modal>

    <form class="header-search-form text-minuscula">

        <div class="col-lg-12 text-center mt-3">

            <div class="row">
                <div class="col-10 text-size-16 text-secondary">
                    <h6>
                        <i class="fas fa-check-circle mr-2 text-success"></i>
                        Validación
                    </h6>
                </div>
                <div class="col-2"><i class="far fa-times-circle text-danger" (click)="modal.dismiss('Cross click')"></i></div>
            </div>

        </div>

        <hr>

        <div class="col-lg-12">


            <div class="form-group">
                <p>
                    <i class="fas fa-id-card-alt mr-2 text-dark"></i>
                    <label class="text-size-14 mb-3">Cédula *</label>
                    <input type="text" class="form-control form-control-sm" name="cedula"
                        [(ngModel)]="recover.cedula">
                </p>
            </div>

            <div class="form-group">
                <p>
                    <i class="fas fa-user-tie mr-2 text-dark"></i>
                    <label class="text-size-14 mb-3">Correo *</label>
                    <input type="text" class="form-control form-control-sm" name="correo"
                        [(ngModel)]="recover.correo">
                </p>
            </div>

            <hr>

            <button type="button" class="btn btn-outline-dark text-size-14 btn-sm btn-block"
                (click)="modal.close('recover')" type="submit">
                Recuperar Contraseña
            </button>

        </div>

        <br>

    </form>

</ng-template>