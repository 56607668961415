<app-menu></app-menu>
<br>
<br>
<br>

<section class="container-fluid">

    <owl-carousel-o [options]="promotionOwl">

        <ng-template carouselSlide *ngFor="let p of promotions">

            <div class="pt-5 divSelect"   >
                <div class="col-lg-12 col-md-12 col-12 p-1"   *ngIf="promotions.length>1" >
                    <div class="card card2" style=" border-radius: 14px;">
                        <img [src]="urlBase+p.imgPromocion+'?alt=media'" style="border-top-left-radius: 14px; border-top-right-radius: 14px;" class="card-img-top imgAll" alt="...">
                        <div class="card-body "  >
                           <p id="pBtnHoteles" class=" "  [ngStyle]="{'color':colorLeter() }" style="font-size:0.85em; margin-top: 0.4em; margin-bottom: 0.4em; text-align: center;"><strong style="font-family: 'Montserrat';"></strong>  {{p.nombrePromocion | uppercase}}</p>
                            
                           <div class=" d-grid gap-2">
                            <button class="btn btn-sm rounded-pill btnCarHabitaciones fw-bold "  style="letter-spacing: 3px; text-transform: uppercase; "  
                             [ngStyle]="{'color':colorUno(), 'border-color':colorUno(), 'border-width':'2px', 'border-style':'solid' } " (click)="selectedPromotion(p)" >
                                Ver habitaciones 
                            </button>
                           </div>
                           <div class="col-12 pt-2">
                            <p class="text-minuscula-texto text-size-14" *ngIf="!p.view">
                                {{p.preliminar}}
                                <span class="text-dark tipo-cursor" *ngIf="p.vermas" (click)="seeMoreDescription(p)">
                                    . . .
                                    <i class="fas fa-eye text-size-15 ml-2"></i>
                                </span>
                            </p>
                            <p class="text-minuscula-texto text-size-14" *ngIf="p.view">
                                {{p.descripcion}}
                                <span class="text-dark tipo-cursor" (click)="seeMoreDescription(p)">
                                    <i class="fas fa-eye-slash text-size-15 ml-2"></i>
                                </span>
                            </p>
            
                        </div>
                        </div>
                      </div>
                  
                </div>
                <div class="col-lg-6 col-md-12 col-12 p-1 "   *ngIf="promotions.length<2" >
                    <div class="card card2" style=" border-radius: 14px;">
                        <img [src]="urlBase+p.imgPromocion+'?alt=media'" style="border-top-left-radius: 14px; border-top-right-radius: 14px;" class="card-img-top imgAll" alt="...">
                        <div class="card-body "  >
                           <p id="pBtnHoteles" class=" "  [ngStyle]="{'color':colorLeter() }" style="font-size:0.85em; margin-top: 0.4em; margin-bottom: 0.4em; text-align: center;"><strong style="font-family: 'Montserrat';"></strong>  {{p.nombrePromocion | uppercase}}</p>
                            
                           <div class=" d-grid gap-2">
                            <button class="btn btn-sm rounded-pill btnCarHabitaciones fw-bold "  style="letter-spacing: 3px; text-transform: uppercase; "  
                             [ngStyle]="{'color':colorUno(), 'border-color':colorUno(), 'border-width':'2px', 'border-style':'solid' } " (click)="selectedPromotion(p)" >
                                Ver habitaciones 
                            </button>
                           </div>
                           <div class="col-12 pt-2">
                            <p class="text-minuscula-texto text-size-14" *ngIf="!p.view">
                                {{p.preliminar}}
                                <span class="text-dark tipo-cursor" *ngIf="p.vermas" (click)="seeMoreDescription(p)">
                                    . . .
                                    <i class="fas fa-eye text-size-15 ml-2"></i>
                                </span>
                            </p>
                            <p class="text-minuscula-texto text-size-14" *ngIf="p.view">
                                {{p.descripcion}}
                                <span class="text-dark tipo-cursor" (click)="seeMoreDescription(p)">
                                    <i class="fas fa-eye-slash text-size-15 ml-2"></i>
                                </span>
                            </p>
            
                        </div>
                        </div>
                      </div>
                  
                </div>
           </div>
            <!-- <div class="pt-5 divSelect" *ngIf="promotions.length<2">
                <div class="col-lg-6 col-md-12 col-12 p-1"   >
                    <div class="card card2" style=" border-radius: 14px;">
                        <img [src]="urlBase+p.imgPromocion+'?alt=media'" style="border-top-left-radius: 14px; border-top-right-radius: 14px;" class="card-img-top imgAll" alt="...">
                        <div class="card-body " >
                           <p id="pBtnHoteles" class=" "  [ngStyle]="{'color':colorLeter() }" style="font-size:0.85em; margin-top: 0.4em; margin-bottom: 0.4em; text-align: center;"><strong style="font-family: 'Montserrat';"></strong>  {{p.nombrePromocion | uppercase}}</p>
                            
                           <div class=" d-grid gap-2">
                            <button class="btn btn-sm rounded-pill btnCarHabitaciones fw-bold "  style="letter-spacing: 3px; text-transform: uppercase; "
                             [ngStyle]="{'color':colorUno(), 'border-color':colorUno(), 'border-width':'2px', 'border-style':'solid' } " (click)="selectedPromotion(p)" >
                                Ver habitaciones 2
                            </button>
                           </div>
                           <div class="col-12 pt-2">
                            <p class="text-minuscula-texto text-size-14" *ngIf="!p.view">
                                {{p.preliminar}}
                                <span class="text-dark tipo-cursor" *ngIf="p.vermas" (click)="seeMoreDescription(p)">
                                    . . .
                                    <i class="fas fa-eye text-size-15 ml-2"></i>
                                </span>
                            </p>
                            <p class="text-minuscula-texto text-size-14" *ngIf="p.view">
                                {{p.descripcion}}
                                <span class="text-dark tipo-cursor" (click)="seeMoreDescription(p)">
                                    <i class="fas fa-eye-slash text-size-15 ml-2"></i>
                                </span>
                            </p>
            
                        </div>
                        </div>
                      </div>
                  
            </div>
           </div> -->

        </ng-template>

    </owl-carousel-o>

</section>

<br>

<app-footer></app-footer>